import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AboutPage.css';
import { TiArrowBack } from "react-icons/ti";
import license1 from '../../images/license/1.jpg';
import license2 from '../../images/license/2.jpg';
import license3 from '../../images/license/3.jpg';
import razreshenie from '../../images/license/5.jpg'

const AboutUsPage = () => {
    const [selectedLicense, setSelectedLicense] = useState(null);

    const licenses = [
        { image: license1, title: "Лицензия 1" },
        { image: license2, title: "Лицензия 2" },
        { image: license3, title: "Лицензия 3" },
        { image: razreshenie, title: "Разрешение" },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    const openLicense = (image) => {
        setSelectedLicense(image);
    };

    const closeModal = () => {
        setSelectedLicense(null);
    };

    return (
        <>
            <button className="back-button" onClick={() => navigate(-1)}>
                <i><TiArrowBack /></i> Назад
            </button>

            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>О компании</h1>
                        <p>
                            Товарищество с ограниченной ответственностью АскомМЕТ работает на рынке Казахстана с 2011 года.
                            Офис компании находится в городе Астане,
                            а также имеются представительства в городах Алматы, Тараз, Талдыкорган, Кызылорда, Атырау, Кокшетау.
                        </p>
                        <h3>Компания осуществляет проектирование, поставку, монтаж, инсталляцию и техническое обслуживание:</h3>
                        <div className='features'>
                            <ul>
                                <li>технических средств защиты информации и обнаружения каналов утечки информации;</li>
                                <li>антитеррористических и досмотровых оборудований;</li>
                                <li>систем противодействия беспилотных летательных средств;</li>
                                <li>систем радиосвязи, системы беспроводной связи Mesh;</li>
                                <li>измерительного оборудования для проведения специальных исследований;</li>
                                <li>оптических приборов (оптических систем дальнего наблюдения, тепловизионных систем);</li>
                                <li>специальных технических средств, для субъектов оперативно-розыскной деятельности.</li>
                            </ul>
                        </div>
                        <h2>Штат компании</h2>
                        <p>Штат компании укомплектован высококвалифицированными специалистами, прошедшими специализированное обучение и имеющими большой опыт работы в сфере технической, телекомуникационной и информационной безопасности. Нашими поставщиками являются непосредственно отечественные и зарубежные производители оборудования, что позволяет существенно сократить сроки доставки и снизить стоимость товара. Отличительной особенностью «АскомМЕТ» является качественное и своевременное выполнение работ, комплексный подход к решению задач любой сложности. Это безусловный приоритет всей деятельности компании «АскомМЕТ». Также одной из главных характеристик компании является чуткое отношение к заказчикам и их потребностям, а также высокая квалификация инженерного состава. Большинство заказчиков, однажды обратившись к нам, становятся нашими постоянными клиентами.</p>
                        <p>С 2018 года на базе «АскомМЕТ» в целях оказания образовательных услуг в области применения технических средств работает Учебный центр (УЦ). Программа курсов первоначального обучения и повышения квалификации разрабатывается индивидуально под функции организации-заказчика, после согласования. Лицам, успешно освоившим соответствующую программу и прошедшим итоговую аттестацию, выдается Сертификат. Кроме того, УЦ предлагает различные ознакомительные семинары для руководителей организации о новинках технических средств и комплексов.</p>
                        <h3>Преимущества обучения в УЦ «АскомМЕТ»</h3>
                        <div className='features'>
                            <ul>
                                <li>Стоимость обучения входит в стоимость приобретенной техники.</li>
                                <li>Постоянным партнерам скидки.</li>
                                <li>Теоретические и практические занятия проводят профессионалы-практики.</li>
                                <li>В учебном процессе принимают участие ведущие разработчики технических средств и комплексов.</li>
                                <li>Прикладная направленность прцоесса обучения.</li>
                            </ul>
                        </div>
                        <h2>Заказчики</h2>
                        <p>Нашими постоянными заказчиками являются государственные организации и частные компании:</p>
                        <div className='about-customer'>
                            <div className='about-customer-left-side'>
                            </div>
                            <div className='about-customer-right-side'>
                                <p className='ots'></p>
                                <div className='customers'>
                                    <div>
                                        <h2>Министерство внутренних дел Республики Казахстан</h2>
                                    </div>
                                    <div>
                                        <h2>Министерство обороны Республики Казахстан</h2>
                                    </div>
                                    <div>
                                        <h2>Комитет Национальной Безопасности Республики Казахстан</h2>
                                    </div>
                                    <div>
                                        <h2>Агентство Республики Казахстан по противодействию коррупции</h2>
                                    </div>
                                    <div>
                                        <h2>Агентство Республики Казахстан по финансовому мониторингу</h2>
                                    </div>
                                    <div>
                                        <h2>Служба государственной охраны Республики Казахстан</h2>
                                    </div>
                                    <div>
                                        <h2>«Қазақстан Темір Жолы»</h2>

                                    </div>

                                    <div>
                                        <h2>«КазМунайГаз»</h2>

                                    </div>



                                    <div>
                                        <h2>Акиматы городов Республики Казахстан</h2>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <h2>Лицензии и Разрешение</h2>
                        <div className="licenses-gallery">
                            {licenses.map((license, index) => (
                                <div key={index} className="license-item" onClick={() => openLicense(license.image)}>
                                    <img
                                        src={license.image}
                                        alt={license.title}
                                        className="license-thumbnail"
                                    />

                                </div>
                            ))}
                        </div>

                        {selectedLicense && (
                            <div className="modal-overlay-1" onClick={closeModal}>
                                <div className="modal-content-1" onClick={(e) => e.stopPropagation()}>
                                    <img src={selectedLicense} alt="Просмотр лицензии" className="full-image" />
                                    <button className="close-button" onClick={closeModal}>
                                        Закрыть
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutUsPage;
