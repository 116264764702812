import React, { useEffect } from 'react';
import dron_0 from '../../images/services-images/dron_0.jpg'
import main from '../../images/services-images/antibpla/main.png'
const Antidrone = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Решения по борьбе с беспилотными летательными аппаратами коммерческого типа</h1>
                        <img src={dron_0} alt='drone' />
                        <p>В условиях бурного развития информационных технологий и быстрого снижения стоимости комплектующих беспилотных летательных аппаратов коммерчческого типа (КомБПЛА), стремительно расширяются масштабы их использования в незаконных целях. Возможность оснастить КомБПЛА хорошей камерой, фотоаппаратом и/или системой передачи видео в реальном времени сильно усложняет задачу охраны частной и государственной собственности.</p>
                        <img src={main} alt='drone' />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Antidrone;
