import React from 'react';
import owl from '../../images/services-images/antibpla/owl-2.png'
import owlmain from '../../images/services-images/antibpla/owlmain.png'
const OWL2 = () => {

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Система обнаружения БЛА «OWL-2»</h1>
                        <div className='bimage'>
                            <img src={owl} alt='garpun' />
                        </div>

                        <p>Система обнаружения предназначена  для автоматического обнаружения радиосигналов беспилотных летательных аппаратов, а также передачи сигнала тревоги на пост охраны.
                        </p>
                        <p>Система является полностью автоматизированной и не требует участия оператора. Обеспечивает обнаружение как профессиональных моделей, управляемых оператором на значительном расстоянии, ориентируясь на поступающий с видеокамеры сигнал, так и моделей нижнего ценового сегмента, требующих присутствия оператора в непосредственной близости от БЛА.
                        </p>
                        <p>Для формирования больших зон безопасности производится развертывание нескольких систем с объединением в единую локальную вычислительную сеть.
                        </p>
                        <p>В случае необходимости, возможна организация централизованного управления с автоматизированного рабочего места оператора.
                        </p>

                        <h2>Изделие обеспечивает:</h2>
                        <div className='spisok'>
                            <p data-number="1">Обнаружение радиосигналов канала управления БЛА в заданных диапазонах частот;</p>
                            <p data-number="2">обнаружение радиосигналов передачи данных БЛА в заданных диапазонах частот.</p>
                        </div>
                        <h2>Система состоит из:</h2>
                        <div className='spisok'>
                            <p data-number="1">Одного и более автоматических постов охраны (АПО);</p>
                            <p data-number="2">Пульта управления и сигнализации (ПУиС).
                                АПО и ПУиС объединяются в единую локальную вычислительную сеть (ЛВС).
                            </p>
                        </div>
                        <h2>Варианты конструктивного исполнения:</h2>
                        <div className='spisok'>
                            <p data-number="1">Мобильное;</p>
                            <p data-number="2">Стационарное.</p>
                        </div>
                        <h2>Диапазон рабочих температур:</h2>
                        <div className='spisok'>
                            <p data-number="1">от - 20°С до + 45°С</p>
                        </div>
                        <h2>Диапазоны рабочих частот:</h2>
                        <div className='spisok'>
                            <p data-number="1">433 МГц;</p>
                            <p data-number="2">868 МГц;</p>
                            <p data-number="3">1200 МГц;</p>
                            <p data-number="4">2400 МГц;</p>
                            <p data-number="5">5800 МГц;</p>
                        </div>
                        <h2>Дальность обнаружения:</h2>
                        <div className='spisok'>
                            <p data-number="1">2500 м</p>
                        </div>
                        <img src={owlmain} alt='garpun' />
                    </div>
                </div>
            </div>
        </>
    );
};

export default OWL2;
