import React from 'react';
import cham from '../../images/services-images/antibpla/cham120.png'
const Chameleon120 = () => {

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Система оптического обнаружения «CHAMELEON-120»</h1>
                        <div className='bimage_vert'>
                            <img src={cham} alt='garpun' />
                        </div>

                        <h2>Минимальная конфигурация Системы:</h2>
                        <div className='spisok'>
                            <p data-number="1">Модуль «Chameleon-120» (возможно подключение до двадцати модулей, при наличии сервера);
                            </p>
                            <p data-number="2">Автоматизированное рабочее место. </p>
                        </div>
                        <h2>Технические характеристики:</h2>
                        <div className='spisok'>
                            <p data-number="1">Сектор наблюдения по азимуту, градус 120;</p>
                            <p data-number="2">Сектор наблюдения по углу места, градус 40;</p>
                            <p data-number="3">Габаритные размеры, мм 1140х650х650;</p>
                            <p data-number="4">Вес, кг 70;</p>
                            <p data-number="5">Диапазон рабочих температур: от -30°С до 45°С;
                            </p>
                            <p data-number="6">Электропитание: 220В/50Гц;                            </p>
                            <p data-number="8">Потребляемая мощность, не более: 700Вт.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Chameleon120;
