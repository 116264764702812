import React, { useEffect } from 'react';
import videomagos from '../../images/services-images/videocam/magos.mp4';

const Magos = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Magos Systems</h1>
                        <p>
                            Magos Systems - ведущий разработчик и поставщик инновационных решений для охраны периметра и систем безопасности на основе радарных технологий. Компания специализируется на создании передового оборудования и программного обеспечения для эффективного обнаружения, классификации и мониторинга угроз в реальном времени.
                        </p>
                        <div className='video-container'>
                            <video 
                                controls 
                                width="100%" 
                                height="auto" 
                                preload="metadata"
                                style={{ borderRadius: '8px', marginTop: '20px' }}
                            >
                                <source src={videomagos} type="video/mp4" />
                                Ваш браузер не поддерживает тег видео.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Magos;
