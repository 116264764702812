import React from 'react';
import ye2 from '../../images/services-images/antibpla/ye2.png'
const YellowBird2 = () => {
    
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Система защиты от БЛА «YELLOWBIRD-2»</h1>
                        <div className='bimage_vert'>
                            <img src={ye2} alt='garpun'/>
                        </div>
                        <p>Система защиты от БЛА предназначена для обнаружения БЛА, приближающихся к объекту, передачи сигнала тревоги на пульт управления и сигнализации, блокирования каналов управления и навигации БЛА.
                        </p>
                        <p>Система является полностью автоматизированной и не требует участия оператора
                        </p>

                        <h2>Изделие обеспечивает:</h2>
                        <div className='spisok'>
                            <p data-number="1">Обнаружение радиосигналов канала управления БЛА (радиолиния Пульт управления - БЛА);</p>
                            <p data-number="2">Обнаружение радиосигналов передачи данных БЛА (радиолиния БЛА - Пульт управления);</p>
                            <p data-number="3">Передачу сигнала тревоги на пульт управления и сигнализации;</p>
                            <p data-number="4">Активацию блока радиоэлектронного блокирования соответствующего частотного диапазона;</p>
                            <p data-number="5">Выбор режима блокирования работы БЛА;</p>
                            <p data-number="6">Работу в автоматическом режиме, с ведением журнала событий;</p>
                            <p data-number="7">Обновление баз данных образцов нарушителей, в том числе модифицированных БЛА;</p>
                            <p data-number="8">Возможность записи неизвестных сигналов для анализа (опционально);</p>
                            <p data-number="9">Изменение дальностей блокирования БЛА под определенный объект (на этапе развертывания системы);</p>
                            <p data-number="10">Отсутствие ложных срабатываний на иные устройства, функционирующие в тех же частотных диапазонах;</p>
                            <p data-number="11">Открытость для интегрирования в неё вновь вводимых систем.</p>
                        </div>
                        <h2>Система состоит из:</h2>
                        <div className='spisok'>
                            <p data-number="1">Модуля обнаружения и радиоэлектронного блокирования  (МОиРБ);</p>
                            <p data-number="2">Пульта управления и сигнализации (ПУиС).</p>
                        </div>
                        <h2>Диапазон рабочих температур:</h2>
                        <div className='spisok'>
                            <p data-number="1">от - 20°С до + 45°С</p>
                        </div>
                        <h2>Диапазоны рабочих частот:</h2>
                        <div className='spisok'>
                            <p data-number="1">433 МГц;</p>
                            <p data-number="2">868 МГц;</p>
                            <p data-number="3">1200 МГц;</p>
                            <p data-number="4">2400 МГц;</p>
                            <p data-number="5">5800 МГц;</p>
                        </div>
                        <h2>Дальность обнаружения:</h2>
                        <div className='spisok'>
                            <p data-number="1">2500 м</p>
                        </div>
                     </div>
                </div>
            </div>
        </>
    );
};

export default YellowBird2;
