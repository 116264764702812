import React, { useEffect } from 'react';
import img1 from '../../images/services-images/sred/talan.jpg'
const Talan = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Анализатор проводных линий TALAN</h1>
                        <p>Цифровой анализатор проводных и телефонных линий TALAN версии 3.0 – это профессиональный прибор позволяющий обследовать проводные линий на наличие подключений несанкционированных устройств. Талан сочетает в себе современные технические методы проверки проводных и телефонных линий и дает расширенные возможности по анализу аналоговых, цифровых и VoIP телефонных систем.</p>
                        <div className='forensic_back_mile'>

                            <img src={img1} alt='oscorp' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Talan;
