import React, { useEffect } from 'react';
import meshimg from '../../images/services-images/mesh/2.png'
const Nbmesh = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>

                        <h1>Серия MimoMesh/Серия SmartMesh/Серия NBMesh</h1>
                        <h2>Беспроводная широкополосная/узкополосная сеть MESH</h2>
                        
                            <img src={meshimg} alt='forensic' />
                        

                       <p>Передовая технология для передачи любых типов данных по радио каналу с максимальной отказоустойчивостью, данная технология позволяет передать видео трансляцию, аудио информацию и другие любые типы данных в местах отсутствие связи: закрытые карьеры, места ограниченные связью, поисково-спасательные операции в трудно доступных местах и тд.</p>
                        <p>Беспроводная широкополосная сетевая радиостанция серии SmartMesh, использующая платформу программного обеспечения (SDR) последнего поколения, с большим расстояннием, высокой скоростью, быстрым доступом к сети, быстрым переключением маршрутов, гибкими сетями, 2×2MIMO, богатым диапазоном частот (частота может быть настроена), богатым интерфейсом, небольшими размерами, легким весом и другими преимуществами. Радиостанция имеет высокую степень интеграции, низкое энергопотребление, компактную конструкцию, модульную/воздушную/портативную/автомобильную/наземную/наружную базовую станцию и другие формы, поддерживая выходную мощность 0,5 Вт×2/1 Вт×2/2 Вт×2/10 Вт×2/20 Вт×2.</p>
                        <p>SmartMesh имеет высокий уровень безопасности и поддерживает несколько режимов шифрования. Мощные характеристики LPI/LPD, поддержка скачкообразной перестройки частоты, интеллектуальный выбор частоты; Поддержка различных режимов работы, «точка-точка», «точка-многоточка», «многоточка-многоточка»; Гибкая сетевая сеть, звездообразная сеть, цепная сеть, ячеистая сеть и топология гибридной сети. Радиостанция SmartMesh может широко использоваться в сетях и управлении интеллектуальными терминалами, такими как дроны, беспилотные транспортные средства, беспилотные корабли, роботы, а также может применяться для объединения в сеть отдельных солдат, спасения и ликвидации последствий стихийных бедствий, экстренной связи, интеллектуальной энергетики, предотвращения лесных пожаров, минной эксплуатации, антитеррористической специальной службы, гражданской противовоздушной обороны и других отраслей.</p>
                        <p>Применительно для правохранительных органов и вооруженных сил.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Nbmesh;
