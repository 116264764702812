import React from 'react';
import cham from '../../images/services-images/antibpla/cham.png'
const Chameleon = () => {

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Система оптического обнаружения «CHAMELEON»</h1>
                        <div className='bimage_vert'>
                            <img src={cham} alt='garpun' />
                        </div>
                        <p>Работа модуля обнаружения основана на методе оптической детекции.
                        </p>
                        <p>Использование оптоэлектронных датчиков машинного зрения с высоким
                            разрешением, позволяет обнаруживать малые БПЛА «коптерного типа» с
характерными размерами не менее 0,3 х 0,3 м. на расстоянии до 1000м. В секторе обзора 60 градусов по горизонтали и 40 градусов по вертикали. Под характерным размером БПЛА подразумевается фронтальная проекция в двух измерениях с коэффициентом заполнения проекции Кз `{'>'}` 0,7.
                        </p>
                        <h2>Минимальная конфигурация Системы:
                        </h2>
                        <div className='spisok'>
                            <p data-number="1">Модуль «Chameleon» (возможно подключение до двадцати модулей, при наличии сервера);
                            </p>
                            <p data-number="2">Автоматизированное рабочее место.</p>
                        </div>
                        <h2>Технические характеристики:</h2>
                        <div className='spisok'>
                            <p data-number="1">Сектор наблюдения по азимуту, градус 60;</p>
                            <p data-number="2">Сектор наблюдения по углу места, градус 40;</p>
                            <p data-number="3">Габаритные размеры, мм 520х620х620;</p>
                            <p data-number="4">Вес, кг 35;</p>
                            <p data-number="5">Диапазон рабочих температур: от -30°С до 45°С;
                            </p>
                            <p data-number="6">Электропитание: 220В/50Гц;                            </p>
                            <p data-number="7">Возможность замены аккумуляторной батареи;</p>
                            <p data-number="8">Потребляемая мощность, не более: 400Вт.
                            </p>
                        </div>
                    
                    </div>
                </div>
            </div>
        </>
    );
};

export default Chameleon;
