// src/components/Vacancies/Vacancies.js
import React from 'react';
import { IoMdTime } from "react-icons/io";
import { BsGeoAlt } from "react-icons/bs";
import { MdWorkOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import './Vacancies.css';

export const vacanciesData = [
    {
        id: 1,
        title: 'Бухгалтер',
        timePosted: '1 день назад',
        workType: 'Полный день',
        experience: '3-6 лет',
        location: 'г. Астана'
    },
    // Добавьте другие вакансии по аналогии
];

const Vacancies = () => {
    return (
        <div className='vacancies-content'>
            <h1>ВАКАНСИИ</h1>
        <div className='vacancies'>
            {vacanciesData.map((vacancy) => (
                <div key={vacancy.id} className='vacancies-block'>
                    <div className='vacancies-description'>
                        <div className='vac-time'>{vacancy.timePosted}</div>
                        <div className='vac-title'>
                            <h2>{vacancy.title}</h2>
                        </div>
                    </div>
                    <div className='vac-bottom'>
                        <div className='vac-icons'>
                            <p><i><IoMdTime /></i>{vacancy.workType}</p>
                            <p><i><MdWorkOutline /></i>{vacancy.experience}</p>
                            <p><i><BsGeoAlt /></i>{vacancy.location}</p>
                        </div>
                        <div className='vac-button'>
                            {/* Заменяем кнопку на компонент Link */}
                            <Link to={`/vacancy/${vacancy.id}`}>
                                <input type='button' value='Подробнее...' />
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </div>
        </div>
    );
};

export default Vacancies;
