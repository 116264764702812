import Carousel from 'react-bootstrap/Carousel';
import Image1 from '../../images/services-images/carousel/1.png';
import Image2 from '../../images/services-images/carousel/2.png';
import Image3 from '../../images/services-images/carousel/3.png';
import Image4 from '../../images/services-images/carousel/SpectraProfessional2SeriesPTZ-Title-1200x900-01.avif'
import Image5 from '../../images/services-images/carousel/Bracket_30_06_1.png'
import Image6 from '../../images/services-images/carousel/peregrine-breadcrumb-full-removebg-preview.png'
import './MyCarousel.css'
function MyCarousel() {
  return (
    <Carousel>
      <Carousel.Item>
        <img src={Image1} alt="First slide" />
        <Carousel.Caption>
          
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img src={Image5} alt="Second slide" />
        <Carousel.Caption>
          
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img src={Image6} alt="Second slide" />
        <Carousel.Caption>
          
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img src={Image2} alt="Second slide" />
        <Carousel.Caption>
          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={Image3} alt="Third slide" />
        <Carousel.Caption>

        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img src={Image4} alt="Third slide" />
        <Carousel.Caption>

        </Carousel.Caption>
      </Carousel.Item>

      
    </Carousel>
  );
}

export default MyCarousel;
