import React from 'react';
import img1 from "../../images/services-images/at/svod.png"
const Svod = () => {

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Генератор сигналов для защиты от БЛА в выделенных диапазонах "СВОД"</h1>
                        <h2>Защита верхней полусферы от коммерческих БПЛА, использующими в своей
                            работе наиболее популярные диапазоны радиочастот:</h2>
                        <div className='screensh'>
                            <img src={img1} alt='svod' />
                        </div>
 
                    </div>
                </div>
            </div>
        </>
    );
};

export default Svod;
