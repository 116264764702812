import React, { useEffect, useRef } from "react";
import './Home.css';
import back from '../../video/1220.mp4';

const Home = ({ onOpenModal }) => {
    const aboutTitleRef = useRef(null);
    const aboutDescriptionRef = useRef(null);
    const contactUsRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (aboutTitleRef.current) {
                aboutTitleRef.current.classList.add('visible');
            }
        }, 300);

        setTimeout(() => {
            if (aboutDescriptionRef.current) {
                aboutDescriptionRef.current.classList.add('visible');
            }
        }, 600);

        setTimeout(() => {
            if (contactUsRef.current) {
                contactUsRef.current.classList.add('visible');
            }
        }, 900);
    }, []);

    return (
        <div className="home-content">
    <video className="background-video" autoPlay muted loop>
        <source src={back} type="video/mp4" />
        Ваш браузер не поддерживает видео.
    </video>
    <div className="video-overlay"></div> {/* Затемнение видео */}
    <div className="home">
        <div className="home-text">
            <div className="home-title" ref={aboutTitleRef}>
                <p>Ваша безопасность -</p>
                <p>Генерируем, Воплощаем!</p>
            </div>
            <div className="home-description" ref={aboutDescriptionRef}>
                <p>Мы предоставляем высококачественные решения 
                в области видеонаблюдения, охранных систем 
                и контроля доступа,
                в целях обеспечения безопасности
                и спокойствия граждан</p>
            </div>
            <div className="contact-us" ref={contactUsRef}>
                <input type="button" value="Связаться с нами" onClick={onOpenModal} />
            </div>
        </div>
    </div>
</div>

    );
};

export default Home;
