import React, { useEffect } from 'react';
import './ServicesPage.css';
import cifrovoj_fon_nachalo_starnicy from '../../images/services-images/cifrovoj_fon_nachalo_starnicy.jpg'
const STS = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Cпециальных технические средства (СТС)</h1>
                        <div className='bimage'>
                            <img src={cifrovoj_fon_nachalo_starnicy} alt=''/>
                        </div>
                        
                        <p>
                            Наша Компания осуществляет разработку, производство, реализацию и ремонт специальных технических 
                            средств (СТС) 
                            для субъектов оперативно-розыскной деятельности (Лицензия АТ № 049), реализацию средств криптографической защиты 
                            информации 
                            (Лицензия СК № 722), а также проводит работы со сведениями, составляющими 
                            государственные 
                            секреты РК (Разрешение №119/33/1224).
                            
                        </p>
                        <p>Проводит поставку, инсталляцию оборудования и систем для выявления каналов утечки информации
                        и выполнения работ по специальным исследованиям и аттестации объектов информатизации.</p>
                        <h2>СТС:</h2>
                        <div className='spisok'>
                            <p data-number="1">Средства для негласного контроля аудио, видеоинформации, в том числе удаленный контроль.</p>
                            <p data-number="2">Оборудование обработки запирающих устройств, охранных сигнализаций.</p>
                            <p data-number="3">АПК анализа биллинга данных сотовых операторов.</p>
                            <p data-number="4">Мобильный комплекс определения идентификаторов мобильных терминалов и их местоположения на базе транспортных средств.</p>
                            <p data-number="5">АПК получения и анализа информации различных цифровых устройств.</p>
                        </div>
                        <div className='warn'>

                        <p className='warning'>
                            <i>
                            Информация касательня СТС предоставляется только субъектам оперативно-розыскной деятельности<br/>
                             +7 (7172) 55-27-93, +7 (7172) 55-27-97 <br/>Республики Казахстан, ZOOX6D6, г. Астана, ул. Сарысу, д.12/1.
                             </i>
                        </p>
                        </div>
                        
                    </div>
                </div>
            </div>

        </>
    );
};

export default STS;
