import React, { useEffect } from 'react';
import screenshot_2 from '../../images/services-images/screenshot_2.png'
const Usj02disc = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Ультразвуковой глушитель USJ-02-Disc</h1>
                        <p>Ультразвуковой глушитель USJ-02-Disc – эффективное устройство, предназначенное для предотвращения несанкционированного прослушивания и записи конфиденциальных разговоров через различные устройства, такие как:</p>
                        <div className='features'>
                            <ul>
                                <li>GSM-передатчики;</li>
                                <li>устройства передающие информацию по радиоканалам;</li>
                                <li>мобильные телефоны;</li>
                                <li>аналоговые и цифровые диктофоны;</li>
                                <li>проводные микрофоны.</li>
                            </ul>
                        </div>
                        <div className='bimage_dlin'><img src={screenshot_2} alt='usj' /></div>
                        <h2>Принцип работы:</h2>
                        <p>Создание направленного излучения сложной структурированной помехи в ультразвуковом диапазоне, неслышимой для человеческого уха, но воздействующей своим звуковым давлением непосредственно на мембрану микрофона;</p>
                        <p>Направленность действия – круговое (360°).</p>
                        <h2>Технические характеристики:</h2>
                        <div className='features'>
                            <ul>
                                <li>источник питания: 12 В постоянного тока;</li>
                                <li>встроенные литий-ионные элементы: 4 х 3,6 В, 3400 мАч;</li>
                                <li>работа от аккумулятора: ~ 3 часа;</li>
                                <li>максимальная потребляемая мощность: 18 Вт;</li>
                                <li>частота ультразвука: 20 кГц;</li>
                                <li>горизонтальный угол ультразвукового луча: 360°;</li>
                                <li>вертикальный угол ультразвукового луча (потеря -6 дБ): ~ 50°;</li>
                                <li>диапазон рабочих температур: от 0 до 30°C;</li>
                                <li>подставка, монтажная панель.</li>
                            </ul>
                        </div>
                        <i>*Предназначено для пользования в помещении</i>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Usj02disc;
