import React, { useEffect } from 'react';
import img1 from '../../images/services-images/sred/andre.png'
const Andre = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Многофункциональный поисковый прибор АNDRE ADVANCED</h1>
                        <p>Многофункциональный поисковый прибор АNDRE предназначен для обнаружения источников радиочастотного сигнала в ближней зоне приема. Прибор сочетает в себе широкополосный детектор электромагнитного поля, приемник инфракрасного диапазона, различные дополнительные зонды для проверки проводных линий и оценки виброакустической защиты помещения. Комплектация прибора имеет широкий набор поисковых зондов и аксессуаров, позволяющих увеличить эффективность поиска. ANDRE является отличным дополнением к спектроанализатору, позволяет провести быструю предварительную оценку радиообстановки.</p>
                        <div className='forensic_back_mile'>

                        <img src={img1} alt='oscorp'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Andre;
