import React, { useEffect } from 'react';
import background_image from '../../images/services-images/forensic/back.jpg'
const Forensic = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Цифровая криминалистика</h1>
                        <div className='forensic_back'>
                            <img src={background_image} alt='forensic' />
                        </div>

                        <p>Компания АскомМЕТ является официальным партнёром ведущих мировых компаний по цифровой криминалистике.</p>
                        <p>Цифровая криминалистика — это ветвь судебной экспертизы, которая занимается исследованием и расследованием киберпреступлений. Она включает в себя использование современных технологий и методов для обнаружения, сбора, анализа и интерпретации цифровых доказательств. </p>
                        <h2>Основные направления цифровой криминалистики:</h2>
                        <div className='spisok'>
                            <p data-number="1">Компьютерная криминалистика</p>
                            <p data-number="2">Криминалистика мобильных устройств</p>
                            <p data-number="3">Сетевая криминалистика</p>
                            <p data-number="4">Криминалистика памяти</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Forensic;
