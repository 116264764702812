import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './Header.css';
import Logo from '../../images/Logo.png';
import { FaBars } from "react-icons/fa";

const Header = ({ activeSection }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isServicesHovered, setIsServicesHovered] = useState(false); // Track hover state for services
    const navigate = useNavigate();
    const menuRef = useRef(null); // Ref for menu
    const menuButtonRef = useRef(null); // Ref for menu button

    const toggleMenu = () => {
        setIsOpen((prev) => !prev);
    };

    const handleScrollToSection = (event, sectionId) => {
        event.preventDefault();

        if (window.location.pathname === "/") {
            const element = document.getElementById(sectionId);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            navigate("/"); // Return to the homepage
            setTimeout(() => {
                const element = document.getElementById(sectionId);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 100);
        }

        setIsOpen(false);
    };

    const handleServicesClick = (e) => {
        e.preventDefault();
        const sectionId = 'services'; // ID of the services section
        handleScrollToSection(e, sectionId); // Scroll to the "УСЛУГИ" section
        setIsServicesHovered(!isServicesHovered); // Toggle dropdown visibility
    };

    const handleLinkClick = (e, path) => {
        e.stopPropagation(); // Prevent the menu toggle from triggering when clicking on a link
        navigate(path); // Navigate to the respective service page
    };

    const handleClickOutside = (event) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target) &&
            menuButtonRef.current &&
            !menuButtonRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <header className="header">
            <a href="/"><img className="logo" src={Logo} alt="logo" /></a>
            <nav ref={menuRef} className={`navbaro ${isOpen ? 'active' : ''}`}>
                <li
                    onClick={(e) => handleScrollToSection(e, 'about')}
                    className={activeSection === 'about' ? 'active' : ''}
                >
                    О НАС
                </li>
                <li
                    onClick={handleServicesClick} // Click handler to both scroll and toggle dropdown
                    onMouseEnter={() => setIsServicesHovered(true)} // Show dropdown on hover
                    onMouseLeave={() => setIsServicesHovered(false)} // Hide dropdown when hover ends
                    className={activeSection === 'services' ? 'active' : ''}
                >
                    УСЛУГИ
                    {isServicesHovered && (
                        <ul className="services-dropdown">
                            <li onClick={(e) => handleLinkClick(e, '/video')}>Системы видеонаблюдения</li>
                            <li onClick={(e) => handleLinkClick(e, '/integratedsecuritysystem')}>Телекомуникации и связь</li>
                            <li onClick={(e) => handleLinkClick(e, '/informsec')}>Средства Защиты Информации</li>
                            <li onClick={(e) => handleLinkClick(e, '/forensic')}>Цифровая криминалистика</li>
                            <li onClick={(e) => handleLinkClick(e, '/antidrone')}>Анти - БПЛА</li>
                            <li onClick={(e) => handleLinkClick(e, '/antiterror')}>Антитеррористическая защита</li>
                            <li onClick={(e) => handleLinkClick(e, '/sts')}>СТС</li>
                            <li onClick={(e) => handleLinkClick(e, '/mesh')}>Система радиосвязи Mesh</li>
                            {/* Add more services links here */}
                        </ul>
                    )}
                </li>
                <li
                    onClick={(e) => handleScrollToSection(e, 'partners')}
                    className={activeSection === 'partners' ? 'active' : ''}
                >
                    ПАРТНЕРЫ
                </li>
                <li
                    onClick={(e) => handleScrollToSection(e, 'news')}
                    className={activeSection === 'news' ? 'active' : ''}
                >
                    НОВОСТИ
                </li>
                <li
                    onClick={(e) => handleScrollToSection(e, 'vacancies')}
                    className={activeSection === 'vacancies' ? 'active' : ''}
                >
                    ВАКАНСИИ
                </li>
                <li
                    onClick={(e) => handleScrollToSection(e, 'contacts')}
                    className={activeSection === 'contacts' ? 'active' : ''}
                >
                    КОНТАКТЫ
                </li>
            </nav>
            <div
                ref={menuButtonRef}
                className="menu"
                onClick={toggleMenu}
            >
                <FaBars />
            </div>
        </header>
    );
};

export default Header;
