import React, { useState, useEffect } from 'react';
import './News.css';
import { Link } from 'react-router-dom';
const News = () => {
    const newsItems = [
        {
            date: "02.09.2022",
            headline: "Как предотвратить и отслеживать нарушения правил дорожного движения с помощью видеоаналитики",
            description: "Большинство исправительных учреждений сегодня имеют устаревшую инфраструктуру коаксиальных кабелей в сочетании с неисправными или ненадежными компонентами, что потенциально может привести к нарушениям безопасности для сотрудников и заключенных.",
            image: require('../../images/news/new1.jpg'), // Replace with actual image paths
        },
        {
            date: "02.09.2022",
            headline: "Безопасный и простой перенос исправительных учреждений на современную VMS",
            description: "Большинство исправительных учреждений сегодня имеют устаревшую инфраструктуру коаксиальных кабелей в сочетании с неисправными или ненадежными компонентами, что потенциально может привести к нарушениям безопасности для сотрудников и заключенных.",
            image: require('../../images/news/2.jpg'), // Replace with actual image paths
        },
        {
            date: "02.09.2022",
            headline: "Система распознавания автомобильных номеров в реальном времени – PELCO VIDEOXPERT PLATES",
            description: "Данные компоненты играют ключевую роль в обеспечении безопасности как сотрудников, так и заключенных от вреда.",
            image: require('../../images/partners/pelco.png'), // Replace with actual image paths
        },
        {
            date: "02.09.2022",
            headline: "Миграция Pelco Endura в VideoXpert",
            description: "Надежные компоненты помогают предотвратить потенциальные угрозы в учреждениях.",
            image: require('../../images/news/4.jpg'), // Replace with actual image paths
        },
        // Add more news items as needed
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(4);

    const totalPages = Math.ceil(newsItems.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        const updateItemsPerPage = () => {
            if (window.innerWidth <= 768) {
                setItemsPerPage(1);
            } else if (window.innerWidth <= 1024) {
                setItemsPerPage(2);
            } else {
                setItemsPerPage(4);
            }
        };

        updateItemsPerPage();
        window.addEventListener('resize', updateItemsPerPage);

        return () => {
            window.removeEventListener('resize', updateItemsPerPage);
        };
    }, []);

    const currentNewsItems = newsItems.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div className="news-content">
            <div className='section-title'>
                <h1>НОВОСТИ</h1>
            </div>
            <div className='news-grid'>
                {currentNewsItems.map((item, index) => (
                    <div className='news-item' key={index}>
                        <div className='news-in-image' style={{ backgroundImage: `url(${item.image})` }} />
                        <div className='headline'>
                            <h2>{item.headline}</h2>
                        </div>
                        <div className='news-in-text'>
                            <Link to={{ pathname: `/news/${index + 1}`, state: { newsItem: item } }}>Узнать подробнее...</Link>
                        </div>
                        <div className='date'>
                            <p>{item.date}</p>
                        </div>
                    </div>
                ))}
            </div>

            {/* Pagination */}
            <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        className={currentPage === index + 1 ? 'active' : ''}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default News;