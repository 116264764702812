import React, { useEffect } from 'react';
import mainimg from '../../images/services-images/videocam/brief.jpg'
import pdfFile from '../../pdf/Briefcam/BriefCam Protect 2023 Platform Datasheet_RU.pdf'
import pdfFile2 from '../../pdf/Briefcam/DataSheet_BriefCam_RU_220922.pdf'
const BriefCam = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>BriefCam</h1>
                        <div className='forensic_back_wise'>

                            <img src={mainimg} alt='hue' />
                        </div>
                        <p>Комплексная платформа анализа видеоконтента BriefCam® позволяет людям,
                            компаниям и сообществам осознать ценность своего контента видеонаблюдения, делая
                            видео доступным для поиска, действенным и поддающимся количественной оценке.
                            Просмотр часов видео в минутах; немедленно реагировать на критические
                            ситуационные изменения в окружающей среде; и количественно анализировать видео,
                            чтобы получить полезную информацию для обеспечения безопасности и принятия
                            оперативных решений на основе данных, при этом эффективно уравновешивая
                            чувствительность, точность и эффективность:
                        </p>
                        <p>В программном обеспечении BriefCam Video Content Analytics используются передовые технологии компьютерного зрения и глубокого обучения, которые позволяют интеллектуально сокращать часы записанного видеоматериала всего за несколько минут, представляя только элементы, представляющие интерес для оператора.</p>
                        <p>Полученный в результате синопсис видеоклип можно легко отфильтровать с помощью мощного пользовательского интерфейса BriefCam Video Content Analytics, чтобы показать только объекты определенного класса (например, определенные типы транспортных средств, или только мужчин или женщин), а также фильтр по цвету, размеру, направлению или скорости движения, позволяющее гораздо быстрее достичь цели.</p>
                        <p>Результаты могут быть связаны с исходными кадрами в BriefCam Video Content Analytics и экспортированы оттуда для дальнейшего использования. Кроме того, программное обеспечение может представлять тепловые карты, показывающие области активности и неактивности (пребывания) субъектов и/или объектов, а также с указанием всех распространенных или заданных путей перемещения.</p>
                        <h3>ПРЕИМУЩЕСТВА РЕШЕНИЯ BriefCam:</h3>
                        <div className='spisok'>
                            <p data-number="1">Поиск виновников бостонского теракта увенчался успехом благодаря использованию уникальной израильской технологии BriefCam. Она позволяет практически мгновенно анализировать огромное количество данных, просматривая часы видеосъемки и отбирая самое важное.</p>
                            <p data-number="2">В Норвегии она использовалась для поимки Андерса Брейвика, ответственного за самый крупный теракт в Европе. Кроме США и Норвегии, технологии BriefCam активно применяются в разных государствах мира, включая Китай, Тайвань и другие.</p>
                            <p data-number="3">Video Synopsis — технология обработки изображений для создания краткой версии оригинальной полной видеозаписи. Позволяет полностью представить все события, случившиеся в часы, запечатленные на видеозаписи, в сжатом клипе длиной в несколько минут. Синопсис одновременно представляет огромное количество объектов и действий, происходивших в разное время, с индексом, отсылающим к оригинальному видеоисточнику; видеоматериалам из онлайн источников и офф-лайн архивов — для немедленного отслеживания событий, и для криминалистики в целях обнаружения доказательств. Система Video Synopsis делает возможным полный обзор видеозаписей и делает просмотр событий составной частью обеспечения безопасности.</p>
                            <p data-number="4">Системы BriefCam способны одновременно индексировать множество действий полученных с разных источников и предоставлять оператору мгновенный доступ к любому событию. Уникальность решения Video Synopsis состоит в том, что оно не является аналитическим продуктом и предназначено для немедленного обнаружения тех или иных событий в общем потоке информации.</p>
                            <p data-number="5">Решения BriefCam одинаково хорошо справляются с информацией, полученной из онлайн-источников (камер видеонаблюдения), оффлайн-архивов (видерекордеров и регистраторов) и востребованы в оперативно-розыскных действиях, криминалистике, следствии и других сферах, требующих немедленного отслеживания событий, обнаружения доказательств и т.п.</p>
                        </div>
                        <h2>Инновации и расширяемая платформа: </h2>
                        <p>Надежный портфель
                            критически важных возможностей видеоаналитики, полностью интегрированный в
                            платформу для лучшей совместной парадигмы.
                        </p>
                        <h2>Непревзойденная точность:</h2>
                        <p>Используйте лучшую на рынке точность для
                            обнаружения и классификации по классам объектов, атрибутам, поведению, а также
                            распознаванию лиц и номерных знаков.</p>
                        <h2>Превосходная производительность:</h2>
                        <p>Эффективно поддерживает
                            требования к аналитике как по запросу, так и в режиме реального времени для полного
                            охвата камеры.</p>
                        <h2>Гибкая архитектура:</h2>
                        <p>Разработана для удовлетворения потребностей бизнеса
                            сегодня и завтра для архитектур развертывания, включая автономные или
                            многосайтовые, универсальные или распределенные крупномасштабные, локальные
                            или облачные, а также пограничные и гибридные развертывания.
                        </p>
                        <h2>Централизованная аналитика и предупреждения:</h2>
                        <p>С помощью
                            BriefCam Hub клиенты, работающие на нескольких площадках, могут централизованно
                            просматривать и анализировать предупреждения, сгенерированные на удаленных
                            площадках, а также визуализацию аналитических панелей со всех площадок.</p>
                        <h2>Простота использования:</h2>
                        <p>Минимальное время окупаемости и самая низкая
                            совокупная стоимость владения повышают производительность, ускоряют достижение
                            цели и обеспечивают конкурентное преимущество.</p>
                    </div>
                    <div className='datasheet_content'>
                        <a className="datasheet" href={pdfFile} download="VMS-Avigilon-Unity-8">Скачать описание BriefCam Protect</a>
                        <a className="datasheet" href={pdfFile2} download="VMS-Avigilon-Unity-8">Скачать описание BriefCam Investigator</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BriefCam;
