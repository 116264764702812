import React, { useEffect } from 'react';
import video1 from "../../images/services-images/video1.jpg"

const Videonablyudeniye = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Видеонаблюдение</h1>
                        <img src={video1} alt='el_set'/>
                        <p>Неотъемлемой частью комплексной системы безопасности предприятия является видеонаблюдение. Системы видеонаблюдения позволяют не только вести мониторинг и просматривать запись, но и задавать реакцию системы безопасности при возникновении тревожных ситуаций. Видеонаблюдение это эффективный инструмент обеспечения безопасности любого предприятия. Демонстративно установленные в торговом зале видеокамеры отпугивают потенциальных преступников; скрытое видеонаблюдение дает возможность руководителю контролировать работу подчиненных ему сотрудников; видеозапись позволяет получать документальный материал о происходящих событиях на предприятии для последующего анализа и в качестве вещественного доказательства.</p>

                        <h3>Этапы реализации системы: от проекта - до эффективной охраны</h3>
                        <div className='spisok'>
                            <p data-number="1">Для того чтобы получить оптимальное видеонаблюдение, необходимо соотнести потребности заказчика с возможностями оборудования для того, чтобы при минимальных вложениях удовлетворить все заявленные требования.</p>
                            <p data-number="2">Вы звоните нам, наши специалисты консультируют вас по вопросам установки видеонаблюдения и составляют примерную смету на полный комплекс услуг - приобретение и установку системы видеонаблюдения.</p>
                            <p data-number="3">Для обследования объекта к вам приезжает наш специалист, с учетом специфики объекта и ваших пожеланий уточняется перечень необходимого оборудования, работ и введения системы в эксплуатацию. Уточняется смета и заключается договор на поставку оборудования и выполнение работ. Для крупных объектов выполняется проект.</p>
                            <p data-number="4">Выполняются монтажные и пусконаладочные работы, согласно срокам и условиям договора.</p>
                            <p data-number="5">На протяжении гарантийного периода мы помогаем Вам максимально эффективно использовать и развивать установленные решения в области видеонаблюдения, контроля доступа и безопасности.</p>
                            <p data-number="6">Мы имеем опыт оснащения системами видеонаблюдения как объектов частной недвижимости, так и производственных, осуществляем работы по техническому обслуживанию данных систем. Если Вы хотите обеспечить безопасность своей квартиры, дачи, магазина или производства на надежном уровне, мы готовы предложить вам реально работающие решения.</p>
                            <p data-number="7">Кроме воплощения комплексных проектов, мы выполняем и отдельные этапы работ: только разработку проекта или только монтаж.</p>
                        </div>
                        <h3>Кроме воплощения комплексных проектов, мы выполняем и отдельные этапы работ: только разработку проекта или только монтаж.</h3>
                        <h2>Аналоговые камеры</h2>
                        <p>В настоящее время в системы видеонаблюдения устанавливают, как правило, аналоговые камеры видеонаблюдения, которые отличаются простотой конструкции и невысокой ценой. Эти видеокамеры представляют собой оптические устройства, матрицы которых формируют видеосигнал из светового потока, проходящего через объектив и группу линз и попадающего на эту матрицу.</p>
                    
                        <h2>Цифровые видеокамеры</h2>
                        <p>Эти видеокамеры имеют блок цифровой обработки сигнала, встроенный веб-браузер и формируют более качественное изображение, которое можно передавать в виде цифрового сигнала по LAN/WAN сетям системы видеонаблюдения. Цифровые видеокамеры применяют на наиболее ответственных участках системы видеонаблюдения. Как правило, цифровые камеры имеют аналоговый и цифровой выходы.</p>

                        <h2>Объективы для камер видеонаблюдения</h2>
                        <p>Объективы устанавливаются на видеокамеры с целью увеличения дальности ее работы, улучшения технических параметров и приспособления видеокамеры к конкретным условиям работы. Для видеонаблюдения за движущимися объектами используют объективы с переменным фокусным расстоянием — трансфокаторы. В условиях быстро меняющейся освещённости применяют объективы с автодиафрагмой. На скрытые камеры скрытой системы видеонаблюдения устанавливаются объективы типа Pin-Hole.</p>

                        <h2>Поворотные устройства для камер видеонаблюдения</h2>
                        <p>Для расширения угла обзора видеокамеры и слежения за движущимися объектами видеонаблюдения, камеры устанавливают на поворотные устройства. Механизм поворотного устройства перемещает видеокамеру в горизонтальном и вертикальном направлениях, и позволяет оператору системы видеонаблюдения просматривать одной видеокамерой достаточно большие площади охраняемой территории.</p>

                        <h2>Записывающие устройства для видеонаблюдения</h2>
                        <p>Устройства записи видеоинформации (видеомагнитофоны, видеорегистраторы, видео рекордеры) предназначены для записи, хранения и последующего воспроизведения изображений, поступающих как от камер, так и от мультиплексора системы видеонаблюдения.</p>

                        <h2>Камеры видеонаблюдения для ночной съемки</h2>
                        <p>Основными преимуществами видеокамеры является способность осуществлять видео документирование в условиях сверх низкой освещенности в цветном режиме, (без применения инфракрасных и других видов осветителей).</p>
                        
                    </div>

                </div>
            </div>
        </>
    );
};

export default Videonablyudeniye;
