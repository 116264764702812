import React, { useEffect } from 'react';
import meshimg from '../../images/services-images/mesh/mesh1.jpeg'
const Mesh = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>

                        <h1>Система радиосвязи Mesh</h1>

                        <div className='forensic_back_wise'>
                            <img src={meshimg} alt='forensic' />
                        </div>

                       <p>Передовая технология для передачи любых типов данных по радио каналу с максимальной отказоустойчивостью, данная технология позволяет передать видео трансляцию, аудио информацию и другие любые типы данных в местах отсутствие связи: закрытые карьеры, места ограниченные связью, поисково-спасательные операции в трудно доступных местах и тд.</p>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Mesh;
