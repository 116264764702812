import React, { useEffect, useRef, useState } from "react";
import './About.css';
import { useNavigate } from "react-router-dom";
import { MdOutlineDoubleArrow } from "react-icons/md";
import MyCarousel from "../Carousel/MyCarousel";

const About = () => {
    const navigate = useNavigate();
    const blockRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.1
        };

        const handleIntersection = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true); // Когда блок появляется, активируем анимацию
                } else {
                    setIsVisible(false); // Когда блок уходит, деактивируем анимацию
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, options);
        const currentBlock = blockRef.current;

        if (currentBlock) {
            observer.observe(currentBlock);
        }

        return () => {
            if (currentBlock) {
                observer.unobserve(currentBlock);
            }
        };
    }, []);

    const handleMoreClick = () => {
        navigate("/aboutus");
    };

    return (
        <div className="about-container" ref={blockRef}>
            <div id="about-background" />
            <div className="dark-overlay"></div>

            <div className="about-us">
                <div className={`carousel-container ${isVisible ? 'slide-in-left' : 'slide-out-left'}`}>
                    <MyCarousel />
                </div>
                <div className={`opis ${isVisible ? 'slide-in-right' : 'slide-out-right'}`}>
                    <h1>АскомМЕТ</h1>
                    <p>Приветствуем на сайте систем безопасности. Компания имеет большой опыт работы в данной сфере и занимает лидирующие позиции на рынке.</p>
                    <p>Мы можем предложить значительный выбор различных инструментов обеспечения безопасности:</p>
                    <ul>
                        <li>
                            системы видеонаблюдения - один из распространенных и эффективных способов
                            обеспечения сохранности имущества и людей, подберем оптимальную систему,
                            в наличии есть абсолютно незаметные устройства, позволяющие осуществлять мониторинг и запись;
                        </li>
                        <li>
                            охранная сигнализация - инструменты данной категории помогут обнаружить нарушителя
                            и известить о появлении постороннего на объекте...
                        </li>
                    </ul>

                    <div className="more-button">
                        <button onClick={handleMoreClick}>Узнать подробнее<i className="more-icon"><MdOutlineDoubleArrow /></i></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
