import React from 'react';
import dirk from '../../images/services-images/dirk.jpg'
import dirkmain from '../../images/services-images/antibpla/dirkmain.png'
const Dirk3 = () => {
    
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Ручной комплекс борьбы с БПЛА коммерческого типа «DIRK-3»</h1>
                        <div className='bimage'>
                        <img src={dirk} alt='garpun'/>
                        </div>
                        
                        <p>  Комплекс «Dirk-3» обеспечивает потерю    управляемости бытовых БЛА  в воздушном пространстве путем прицельного блокирования каналов управления  и/или каналов навигационного обеспечения(GPS/ГЛОНАСС/Beidou), лишая  возможности определения своего местоположения и получения команд управления  БЛА от оператора. Возможен стационарный вариант исполнения, при котором  комплекс будет установлен на двухосевой ротатор с внешним пультом управления.</p>

                        <h2>Изделие обеспечивает:</h2>
                        <div className='spisok'>
                            <p data-number="1">Активацию комплекса нажатием на спусковой крючок;</p>
                            <p data-number="2">Визуально контролируемое блокирование БЛА;</p>
                            <p data-number="3">Выбор режима помехового воздействия на БЛА*;</p>
                            <p data-number="4">Количество блокируемых диапазонов – до 8;</p>
                            <p data-number="5">Блокирование систем навигации GPS, ГЛОНАСС, Beidou, Galileo;</p>
                            <p data-number="6">Продолжительность работы от полностью заряженной АКБ не менее 30 минут;</p>
                            <p data-number="7">Возможность замены аккумуляторной батареи;</p>
                            <p data-number="8">дальность действия ограничена дальностью визуального контроля БЛА.</p>
                        </div>
                        <h2>Варианты конструктивного исполнения:</h2>
                        <div className='spisok'>
                            <p data-number="1">Мобильное;</p>
                            <p data-number="2">Стационарное.</p>
                        </div>
                        <h2>Диапазон рабочих температур:</h2>
                        <div className='spisok'>
                            <p data-number="1">от - 20°С до + 45°С</p>
                        </div>
                        <img src={dirkmain} alt='garpun'/>
                     </div>
                </div>
            </div>
        </>
    );
};

export default Dirk3;
