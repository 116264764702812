import React, { useEffect } from 'react';
import mainimg from '../../images/services-images/videocam/1.png'

import pdfFile from '../../pdf/Avigilon/avigilon-ai-nvr-2-datasheet-ru.pdf'
import pdfFile2 from '../../pdf/Avigilon/avigilon-unity-video-datasheet-ru.pdf'
const Avigilon = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Avigilon Unity</h1>
                        <div className='forensic_back_avigilon'>
                            <img src={mainimg} alt='main' />
                           
                        </div>
                        
                        <h2>Видео Avigilon Unity</h2>
                        <p>Локальная система управления видео (VMS), специально созданная для предоставления организациям полного контроля над данными и развертываниями платформы управления видео.</p>
                        <h2>Полный ИТ- надзор</h2>
                        <p>Обеспечьте вашим ИТ- отделам полный контроль над данными вашей системы управления видео.</p>
                        <h2>Сертифицировано для специализированных развертываний</h2>
                        <p>Защищенная система VMS с возможностью создания избыточности и соответствия отраслевым стандартам хранения.</p>
                        <h2>Кибербезопасная платформа</h2>
                        <p>Защищенная система VMS с возможностью создания избыточности и соответствия отраслевым стандартам хранения.</p>
                        <p>Системы управления видео от Avigilon объединяют ваши камеры, устройства контроля доступа и аналитику с помощью простой в использовании платформы для более грамотного и эффективного управления объектами</p>
                        <h2>Полная информированность о ситуации</h2>
                        <p>Локальная платформа физической защиты на основе ИИ объединяет системы охранного видеонаблюдения, контроля доступа и гибкого управления в облаке в рамках единого централизованного и масштабируемого решения</p>
                        <h2>Универсальность облака</h2>
                        <p>Разверните полностью облачную систему управления видео, чтобы осуществлять контроль и получать важную информацию в реальном времени из любой точки</p>
                        <h2>Под управлением ИИ</h2>
                        <p>Интуитивно понятное, простое в использовании программное обеспечение способно адаптироваться под ваши потребности, чтобы вовремя предупреждать о необходимости принятия мер</p>
                        <div className='datasheet_content'>
                        <a  className="datasheet" href={pdfFile2} download="VMS-Avigilon-Unity-8">Скачать описание VMS Avigilon Unity 8</a>
                        <a  className="datasheet" href={pdfFile} download="Avigilon-ai-nvr">Сетевой видеорегистратор с
                        искусственным интеллектом 2-го поколения (NVR 2 на базе ИИ)</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Avigilon;
