import React, { useEffect } from 'react';
import wise from "../../images/services-images/videocam/wise.png"
const Wisenet = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Wisenet</h1>
                        <div className='forensic_back_wise'>

                        <img src={wise} alt='wise'/>
                        </div>
                        <p>Hanwha Techwin выпускает несколько серий: Wisenet L, Wisenet Q, Wisenet X, Wisenet P, Wisenet T. Камеры каждой линейки отвечают определенным требованиям и относятся к разным ценовым сегментам.</p>
                        <p>Wisenet L (Lite) включает бюджетные решения с базовым функционалом и только необходимыми опциями.</p>
                        <p>В серию T входят нестандартные узкоспециализированные устройства — тепловизионные и поворотные взрывозащищенные камеры.</p>
                        <p>Серия P состоит из камер высокого разрешения премиум-класса, обеспечивающих обзор от 180 до 360° оснащенных объективом «рыбий глаз», купольных и цилиндрических, мультисенсорных панорамных с широкоугольными объективами и поворотных, отраслевых решений (например, для ритейла).</p>
                        <p>Wisenet Q — линейка компактных устройств, идеально сбалансированных по цене и функционалу. В нее входят купольные и цилиндрические модели внутреннего и уличного исполнения, поворотные камеры.</p>
                        <p>Камеры серии X базируются на мощном чипсете Wisenet 5 с новейшей архитектурой, ускоряющей обработку изображения и значительно расширяющей возможности внедрения аналитических функций.</p>
                        <h2>Поворотные камеры Wisenet</h2>
                        <p>Сетевые и аналоговые поворотные камеры Wisenet внутреннего и уличного исполнения укомплектованы объективами с переменным фокусным расстоянием. Корпуса уличных защищены от влаги и пыли по стандартам IP66–67, от вандализма — IK08–10, от коррозии — по NEMA 4X.</p>
                        <p>В зависимости от модели скорость поворота и наклона достигает 700° в секунду. Предусмотрены предустановки, туры, зоны маскирования и приватности, стоп-кадр (съемка прекращается на время позиционирования объектива), аналитические функции. Отличительная черта Speed Dome устройств Wisenet — автоматическое сопровождение движущегося объекта.</p>
                        <p>Автотрекинг — интеллектуальная функция PTZ устройств. Реализована оптико-механическим способом. Камера сама поворачивается за объектом, оставляя его в центре кадра, и подстраивает зум при изменении расстояния. Цель автотрекинга — автоматизация охраны, слежение без участия оператора.</p>
                        <p>Когда в кадре нет движения, камера мониторит территорию в заданном режиме (например, тур). Оптическое увеличение при этом на минимуме, а угол обзора, наоборот, максимален. Обнаружив движение или получив сигнал с обычной камеры, поворотная сосредотачивается на объекте, наводится, подстраивается, сопровождает, снимает и записывает все данные в архив. Команды, поступающие на поворотный механизм, генерирует процессор.</p>
                        <h2>Аналоговые камеры видеонаблюдения Wisenet</h2>
                        <p>Под брендом Wisenet в рамках серии Wisenet HD+ Hanwha Techwin выпускает аналоговые камеры стандарта AHD, передающие качественное (без артефактов) Full HD (1080p) видео по коаксиальному кабелю на расстояние до 500 м. Оборудование разработано специально для тех, кто по каким-либо причинам не планирует переход к IP видеонаблюдению, но нуждается в хорошей детализации картинки, для плавного апгрейда от аналога к IP, для сложных систем, включающих разное оборудование.</p>
                        <p>Поддержка AHD стандарта открывает возможность использования уже существующей кабельной сети. Функция Plug & Play избавляет от необходимости применения кодировщиков, коммутаторов, преобразователей. С шумами прекрасно справляется SSNR IV–V (фирменная технология шумоподавления), с последствиями перепадов освещенности — DWDR. В ассортименте купольные, цилиндрические, корпусные (без объектива), панорамные и скоростные поворотные AHD камеры, внутренние и уличные, с ИК-подсветкой и без.</p>
                        <h2>Оборудование для видеонаблюдения на транспорте</h2>
                        <p>В линейку специализированного оборудования, предназначенного для установки на транспорте, входят сетевые камеры видеонаблюдения и видеорегистратор. Сетевые камеры и регистраторы, предназначенные для установки конкретно в транспортных средствах, адаптированы к работе в сложных условиях (вибрация, перепады уровней освещения, температурные перепады etc), оснащены авиационными разъемами M12, корпусы защищены от влаги, пыли, вандализма.</p>
                        <h2>Видеорегистраторы Wisenet</h2>
                        <p>Hanwha Techwin выпускает аналоговые и сетевые видеорегистраторы: NVR — от 4-канальных до 64-канальных с поддержкой 4K Ultra HD, камер с разрешением до 12 Мп, RAID 5/6, H.265, WiseStream, независимыми видеовыходами, установкой до 12 HDD объемом до 6 ТБ; DVR — CVBS и AHD (серия HD+) от 4-канальных до 16-канальных.</p>
                        <p>Отличительная черта некоторых сетевых видеорегистраторов Wisenet — встроенный PoE коммутатор, то есть устройства готовы к работе «из коробки», дополнительных коммутирующих приборов не нужно. Поддерживаемый стандарт питания — PoE+: к регистратору можно подключать энергоемкие камеры, в том числе Speed Dome, с регулируемой ИК-подсветкой и обогревателем.</p>
                        <p>Аналоговые регистраторы серии HD+ работают по стандарту AHD, оснащены выходами VGA и HDMI, поддерживают одновременную запись по всем каналам в real-time, аудио. Обеспечивают многопотоковую передачу, в том числе на смартфоны (Android и iOS). Ширину полосы пропускания можно настроить в границах до 64 Мбит/с.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Wisenet;
