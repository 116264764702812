import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Services from "./components/Services/Services";
import Preloader from "./components/Preloader/Preloader";
import Partners from "./components/Partners/Partners";
import News from "./components/News/News";
import Vacancies from "./components/Vacancies/Vacancies";
import Contacts from "./components/Contacts/Contacts";
import BackToTop from "./components/BackToTop/BackToTop";
import ContactButton from "./components/ContactButton/ContactButton";
import AboutUsPage from "./pages/About/AboutPage";

import VacancyDetail from "./pages/VacancyDetail/VacancyDetail";
import NewsDetail from "./components/NewsDetail/NewsDetail";

// ANTITERROR
import Antiterror from "./pages/ServicesPage/Antiterror";
import Atshet from "./pages/Antiterror/Atshet";
import Navbar from "./components/Navbar/Navbar";
import Filin from "./pages/Antiterror/Filin"
import Svod from "./pages/Antiterror/Svod"
import Pole from "./pages/Antiterror/Pole"
import Kurator from "./pages/Antiterror/Kurator";
import Ten from "./pages/Antiterror/Ten";

// ANTI-BPLA
import Antidrone from "./pages/ServicesPage/Antidrone";
import Dirk3 from "./pages/Antidrone/Dirk3";
import Owl2 from "./pages/Antidrone/OWL2";
import YellowBird2 from "./pages/Antidrone/YellowBird2";
import YellowBirdM2 from "./pages/Antidrone/YellowBirdM2";
import YellowBird2P from "./pages/Antidrone/YellowBird2P";
import Chameleon from "./pages/Antidrone/Chameleon"
import Chameleon120 from "./pages/Antidrone/Chameleon120";
import Delta from "./pages/Antidrone/Delta"

//InformSec
import InformSec from "./pages/ServicesPage/InformSec";
import Usj02disc from "./pages/InformSec/Usj-02-disc";
import Andre from "./pages/InformSec/Andre";
import Orion from "./pages/InformSec/Orion";
import Oscorp from "./pages/InformSec/Oscorp";
import Sonata from "./pages/InformSec/Sonata";
import Talan from "./pages/InformSec/Talan";

//Forensic
import Forensic from "./pages/ServicesPage/Forensic";
import OS from "./pages/Forensic/OS";
import HW from "./pages/Forensic/HW";

//STS
import STS from "./pages/ServicesPage/STS";
import Sredstva from "./pages/STS/Sredstva";
import Oborudovanie from "./pages/STS/Oborudovanie";
import ApkSotoviy from "./pages/STS/ApkSotoviy";
import MobilniyKompleks from "./pages/STS/MobilniyKompleks";
import ApkTsifrovoy from "./pages/STS/ApkTsifrovoy";

//IntegratedSecuritySystem
import IntegratedSecuritySystem from "./pages/ServicesPage/IntegratedSecuritySystem";
import Elektricheskiyeseti from "./pages/IntegratedSecuritySystem/Elektricheskiye-seti";
import IntellektualnoyeZdaniye from "./pages/IntegratedSecuritySystem/Intellektualnoye-zdaniye";
import Kompyuternyyeseti from "./pages/IntegratedSecuritySystem/Kompyuternyye-seti";
import Okhrannayasignalizatsiya from "./pages/IntegratedSecuritySystem/Okhrannaya-signalizatsiya";
import Pozharnayasignalizatsiya from "./pages/IntegratedSecuritySystem/Pozharnaya-signalizatsiya";
import Sistemaumnyydom from "./pages/IntegratedSecuritySystem/Sistema-umnyy-dom";
import Skud from "./pages/IntegratedSecuritySystem/Skud";
import Telefonnyyekommunikatsii from "./pages/IntegratedSecuritySystem/Telefonnyye-kommunikatsii";
import Videonablyudeniye from "./pages/IntegratedSecuritySystem/Videonablyudeniye";


//Video
import VideoCam from "./pages/ServicesPage/VideoCam";
import Avigilon from "./pages/VideoCam/Avigilon";
import BriefCam from "./pages/VideoCam/BriefCam";
import Milestone from "./pages/VideoCam/Milestone";
import Wisenet from "./pages/VideoCam/Wisenet";
import Pelco from "./pages/VideoCam/Pelco";
import Magos from "./pages/VideoCam/Magos";

//MESH
import Mesh from "./pages/ServicesPage/Mesh";
import Rajant from "./pages/Mesh/Rajant";
import Ace6 from "./pages/Mesh/Ace6";
import Nbmesh from "./pages/Mesh/Nbmesh";


import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser';

const App = () => {
    
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();
        setIsSending(true);  // Включаем индикатор отправки

        emailjs.sendForm('service_fd9rb2w', 'template_bprwxqd', e.target, 'vajjZgIrn84IeRxz2')
            .then((result) => {
                console.log('Email sent successfully', result.text);

                // Показать успешное уведомление
                Swal.fire({
                    title: 'Успех!',
                    text: 'Ваше сообщение успешно отправлено.',
                    icon: 'success',
                    confirmButtonText: 'Ок',
                    customClass: {
                        confirmButton: 'custom-alert-button',
                    },
                }).then(() => {
                    setIsSending(false);  // Отключаем индикатор отправки
                    closeModal();
                });
            }, (error) => {
                console.log('Error sending email', error.text);

                // Показать ошибку
                Swal.fire({
                    title: 'Ошибка!',
                    text: 'Не удалось отправить сообщение. Попробуйте позже.',
                    icon: 'error',
                    confirmButtonText: 'Ок',
                    customClass: {
                        confirmButton: 'custom-alert-button',
                    },
                }).then(() => {
                    setIsSending(false);  // Отключаем индикатор отправки
                });
            });
        const closeModal = () => {
            setOpen(false);  // Close the modal by setting `open` to false
        };
    };
    return (
        
        <Router>
            
            <div className="App">
            
                {loading ? (
                    <Preloader />
                ) : (
                    <>
                        <Header />
                        <ContactButton onOpen={handleOpen} />
                        {open && (
                            <div className="modal modal-enter">
                                <div className="modal-content modal-enter-active">
                                    <div className="modal-header">
                                        <h2>Связаться с нами</h2>
                                        <button className="close-button" onClick={handleClose}>×</button>
                                    </div>
                                    <form onSubmit={sendEmail}>
                                        <input type="text" name="name_from" placeholder="Ваше имя" required />
                                        <input type="email" name="email_from" placeholder="Ваш email" required />
                                        <input type="tel" name="phone_from" placeholder="Ваш телефон" required />
                                        <div className='input-text-modal'>
                                            <textarea name="message" placeholder="Комментарий"></textarea>
                                        </div>
                                        <input
                                            type="submit"
                                            value={isSending ? "Отправка..." : "Отправить"}
                                            disabled={isSending}  // Блокируем кнопку при отправке
                                        />
                                    </form>

                                    <p className="disclaimer">
                                        Нажав 'Отправить', вы соглашаетесь с обработкой вашей информации в соответствии с нашей Политикой конфиденциальности.
                                    </p>
                                </div>
                            </div>
                        )}
                        <Routes>
                            <Route path="/" element={
                                <>
                                    <Home onOpenModal={handleOpen} />
                                    
                                    <div id="about">
                                        <About />
                                    </div>
                                    <div id="services">
                                        <Services />
                                    </div>
                                    <div id="partners">
                                        <Partners />
                                    </div>
                                    <div id="news">
                                        <News />
                                    </div>
                                    <div id="vacancies">
                                        <Vacancies />
                                    </div>
                                    <div id="contacts">
                                        <Contacts />
                                    </div>
                                    <BackToTop />
                                </>
                            } />
                            <Route path="/vacancy/:id" element={<VacancyDetail />} />
                            <Route path="/news/:id" element={<NewsDetail />} />
                            <Route path="/aboutus" element={<AboutUsPage />} />

                            <Route path="/integratedsecuritysystem" element={
                                <>
                                    <Navbar />
                                    <IntegratedSecuritySystem />
                                </>
                            } />

                            <Route path="/integratedsecuritysystem/elektricheskiye-seti" element={
                                <>
                                    <Navbar />
                                    <Elektricheskiyeseti />
                                </>
                            } />
                            <Route path="/integratedsecuritysystem/intellektualnoye-zdaniye" element={
                                <>
                                    <Navbar />
                                    <IntellektualnoyeZdaniye />
                                </>
                            } />
                            <Route path="/integratedsecuritysystem/kompyuternyye-seti" element={
                                <>
                                    <Navbar />
                                    <Kompyuternyyeseti />
                                </>
                            } />
                            <Route path="/integratedsecuritysystem/okhrannaya-signalizatsiya" element={
                                <>
                                    <Navbar />
                                    <Okhrannayasignalizatsiya />
                                </>
                            } />
                            <Route path="/integratedsecuritysystem/pozharnaya-signalizatsiya" element={
                                <>
                                    <Navbar />
                                    <Pozharnayasignalizatsiya />
                                </>
                            } />
                            <Route path="/integratedsecuritysystem/sistema-umnyy-dom" element={
                                <>
                                    <Navbar />
                                    <Sistemaumnyydom />
                                </>
                            } />
                            <Route path="/integratedsecuritysystem/skud" element={
                                <>
                                    <Navbar />
                                    <Skud />
                                </>
                            } />
                            <Route path="/integratedsecuritysystem/telefonnyye-kommunikatsii" element={
                                <>
                                    <Navbar />
                                    <Telefonnyyekommunikatsii />
                                </>
                            } />
                            <Route path="/integratedsecuritysystem/videonablyudeniye" element={
                                <>
                                    <Navbar />
                                    <Videonablyudeniye />
                                </>
                            } />

                            <Route path="/antiterror" element={
                                <>
                                    <Navbar />
                                    <Antiterror />
                                </>
                            } />
                            <Route path="/antiterror/atshet" element={
                                <>
                                    <Navbar />
                                    <Atshet />
                                </>
                            } />
                            <Route path="/antiterror/filin" element={
                                <>
                                    <Navbar />
                                    <Filin />
                                </>
                            } />
                            <Route path="/antiterror/kurator" element={
                                <>
                                    <Navbar />
                                    <Kurator />
                                </>
                            } />
                            <Route path="/antiterror/pole" element={
                                <>
                                    <Navbar />
                                    <Pole />
                                </>
                            } />
                            <Route path="/antiterror/svod" element={
                                <>
                                    <Navbar />
                                    <Svod />
                                </>
                            } />
                            <Route path="/antiterror/ten" element={
                                <>
                                    <Navbar />
                                    <Ten />
                                </>
                            } />

                            <Route path="/antidrone" element={
                                <>
                                    <Navbar />
                                    <Antidrone />
                                </>
                            } />


                            <Route path="/antidrone/dirk-3" element={
                                <>
                                    <Navbar />
                                    <Dirk3 />
                                </>
                            } />
                            <Route path="/antidrone/owl2" element={
                                <>
                                    <Navbar />
                                    <Owl2 />
                                </>
                            } />
                            <Route path="/antidrone/yellowbird-2" element={
                                <>
                                    <Navbar />
                                    <YellowBird2 />
                                </>
                            } />
                            <Route path="/antidrone/yellowbird-M2" element={
                                <>
                                    <Navbar />
                                    <YellowBirdM2 />
                                </>
                            } />
                            <Route path="/antidrone/yellowbird-2P" element={
                                <>
                                    <Navbar />
                                    <YellowBird2P />
                                </>
                            } />
                            <Route path="/antidrone/chameleon" element={
                                <>
                                    <Navbar />
                                    <Chameleon />
                                </>
                            } />
                            <Route path="/antidrone/chameleon120" element={
                                <>
                                    <Navbar />
                                    <Chameleon120 />
                                </>
                            } />
                            <Route path="/antidrone/delta" element={
                                <>
                                    <Navbar />
                                    <Delta />
                                </>
                            } />

                            <Route path="/informsec" element={
                                <>
                                    <Navbar />
                                    <InformSec />
                                </>
                            } />
                            <Route path="/informsec/andre" element={
                                <>
                                    <Navbar />
                                    <Andre />
                                </>
                            } />
                            <Route path="/informsec/orion" element={
                                <>
                                    <Navbar />
                                    <Orion />
                                </>
                            } />
                            <Route path="/informsec/oscorp" element={
                                <>
                                    <Navbar />
                                    <Oscorp />
                                </>
                            } />
                            <Route path="/informsec/sonata" element={
                                <>
                                    <Navbar />
                                    <Sonata />
                                </>
                            } />
                            <Route path="/informsec/talan" element={
                                <>
                                    <Navbar />
                                    <Talan />
                                </>
                            } />
                            <Route path="/informsec/usj-02-disc" element={
                                <>
                                    <Navbar />
                                    <Usj02disc />
                                </>
                            } />
                            <Route path="/forensic" element={
                                <>
                                    <Navbar />
                                    <Forensic />
                                </>
                            } />
                            <Route path="/forensic/os" element={
                                <>
                                    <Navbar />
                                    <OS />
                                </>
                            } />
                            <Route path="/forensic/hw" element={
                                <>
                                    <Navbar />
                                    <HW />
                                </>
                            } />
                            <Route path="/sts" element={
                                <>
                                    <Navbar />
                                    <STS />
                                </>
                            } />
                            <Route path="/sts/sredstva" element={
                                <>
                                    <Navbar />
                                    <Sredstva />
                                </>
                            } />
                            <Route path="/sts/oborudovanie" element={
                                <>
                                    <Navbar />
                                    <Oborudovanie />
                                </>
                            } />
                            <Route path="/sts/apksotoviy" element={
                                <>
                                    <Navbar />
                                    <ApkSotoviy />
                                </>
                            } />
                            <Route path="/sts/mobilniykompleks" element={
                                <>
                                    <Navbar />
                                    <MobilniyKompleks />
                                </>
                            } />
                            <Route path="/sts/apktsifrovoy" element={
                                <>
                                    <Navbar />
                                    <ApkTsifrovoy />
                                </>
                            } />

                            <Route path="/mesh" element={
                                <>
                                    <Navbar />
                                    <Mesh />
                                </>
                            } />
                            <Route path="/mesh/rajant" element={
                                <>
                                    <Navbar />
                                    <Rajant />
                                </>
                            } />
                            <Route path="/mesh/ace6" element={
                                <>
                                    <Navbar />
                                    <Ace6 />
                                </>
                            } />
                            <Route path="/mesh/nbmesh" element={
                                <>
                                    <Navbar />
                                    <Nbmesh />
                                </>
                            } />

                            <Route path="/video" element={
                                <>
                                    <Navbar />
                                    <VideoCam />
                                </>
                            } />
                            <Route path="/video/avigilon" element={
                                <>
                                    <Navbar />
                                    <Avigilon />
                                </>
                            } />
                            <Route path="/video/briefcam" element={
                                <>
                                    <Navbar />
                                    <BriefCam />
                                </>
                            } />
                            <Route path="/video/milestone" element={
                                <>
                                    <Navbar />
                                    <Milestone />
                                </>
                            } />

                            <Route path="/video/wisenet" element={
                                <>
                                    <Navbar />
                                    <Wisenet />
                                </>
                            } />

                            <Route path="/video/pelco" element={
                                <>
                                    <Navbar />
                                    <Pelco />
                                </>
                            } />
                            <Route path="/video/magos" element={
                                <>
                                    <Navbar />
                                    <Magos />
                                </>
                            } />

                        </Routes>
                    </>
                )}
            </div>
        </Router>
    );
};

export default App;
