import React from 'react';
import img1 from "../../images/services-images/at/kur1.png"
import img2 from "../../images/services-images/at/kur2.png"
import img3 from "../../images/services-images/at/kur3.png"
const Kurator = () => {

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Досмотровые комплексы серии</h1>
                        <p>Применяются на КПП для досмотра днища, арок и иных труднодоступных
                            мест легковых и грузовых транспортных средств. Комплекс удобен для
                            длительной эксплуатации и прост в применении. </p>
                        <p>В основе лежит высокоточный магниточувствительный датчик, реагирующий
                            на наличие любых магнитов.</p>
                        <h2>Куратор</h2>
                        <div className='lgscreen'>
                            <img src={img1} alt='kur' />
                        </div>
                        <h2>Куратор - ЗМ</h2>
                        <p>Оснащен магниточувствительным датчиком и
                            сферическим зеркалом</p>
                        <p>Сигнализация наличия магнита происходит посредствам
                            светодиодной индикации и, при необходимости,
                            сопровождается звуковым оповещением.</p>
                        <div className='lgscreen'>
                            <img src={img2} alt='kur' />
                        </div>
                        <h2>Куратор - КМ</h2>
                        <p>Оснащен камерой со светодиодной
                            подсветкой и функцией формирования
                            панорамного снимка, полноцветным
                            дисплеем и возможностью беспроводной
                            передачи данных в режиме Online на пульт
                            оператора дополнительного контроля.</p>
                            <div className='lgscreen'>
                            <img src={img3} alt='kur' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Kurator;
