import React from 'react';
import img1 from "../../images/services-images/at/ten1.png"
import img2 from "../../images/services-images/at/ten2.png"
import img3 from "../../images/services-images/at/ten3.png"
const Ten = () => {

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Система "ТЕНЬ"</h1>

                        <p>Представляет собой программно-аппаратный
                            комплекс формирования ретрансляционных помех.
                            Предотвращает несанкционированную передачу
                            данных по каналам сотовой связи на режимных
                            объектах.</p>
                        <div className='screenshoot'>
                            <img src={img1} alt='svod' />
                        </div>
                        <div className='screenshoot'>
                            <img src={img2} alt='svod' />
                        </div>
                        <div className='screenshoot'>
                            <img src={img3} alt='svod' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Ten;
