import React from 'react';

const Delta = () => {
    
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Аппаратно-программный комплекс «СИТУАЦИОННЫЙ ЦЕНТР DELTA»</h1>
                        <p>Аппаратно-программный комплекс противодействия БЛА «Ситуационный центр «Delta» предназначен для управления и совместного использования нескольких комплексов противодействия БЛА, в том числе основанных на различных физических принципах (радио и оптических), и обеспечивает формирование целостного визуального представления оперативной обстановки, предоставление оператору гибкого набора инструментов для управления средствами сканирования и блокирования, анализа данных и поддержки принятия решений, а именно:
                        </p>
                        <h2>Изделие обеспечивает:</h2>
                        <div className='spisok'>
                            <p data-number="1">контроль состояния радиотехнических и оптических средств (датчиков) обнаружения и защиты воздушного пространства от несанкционированного применения БЛА;
                            </p>
                            <p data-number="2">управление (включение/выключение/контроль состояния) радиотехническими датчиками контроля воздушного пространства (средствами сканирования) в ручном и автоматическом режимах;
                            </p>
                            <p data-number="3">управление (включение/выключение/контроль состояния) средствами блокирования БЛА в ручном и автоматическом режимах;
                            </p>
                            <p data-number="4">прием данных от датчиков контроля воздушного пространства с записью информации в базу данных в темпе поступления;
                            </p>
                            <p data-number="5">визуализация базовой (цифровые карты, модель охраняемого объекта и посты контроля) и оперативной (обновляемой в реальном времени по результатам радиоконтроля) геопространственной информации в двумерном и трехмерном представлении;
                            </p>
                            <p data-number="6">отображение не менее 10 базовых растровых и векторных слоев цифровой карты с возможностью переключения, изменения прозрачности, настройки параметров визуализации;
                            </p>
                            <p data-number="7">поддержка визуальных инструментов измерения расстояния, площади, высоты рельефа;
                            </p>
                            <p data-number="8">отображение модели охраняемого объекта, постов контроля воздушного пространства, камер наблюдения и иных технических средств в двумерном и трехмерном представлении;
                            </p>
                            <p data-number="9">отображение зон покрытия датчиков радиоконтроля (далее - датчиков) в виде эллипсоидов (кругов) с секторами, соответствующими секторам радиоконтроля;
                            </p>
                            <p data-number="10">приём и отображение данных, полученных от датчиков, в табличном виде в режиме времени, близком к реальному с возможностью фильтрации, сортировки и поиска накопленных данных;
                            </p>
                            <p data-number="11">приём и отображение данных, полученных от датчиков, на геопространственной основе, а именно: выделение секторов, в области которых зафиксирован радиосигнал от БЛА, с отображением информации о характеристиках сигнала, а также изображение БЛА, полученное от средств оптического обнаружения;
                            </p>
                            <p data-number="12">информирование оператора/дежурного об обнаруженных БЛА с помощью настраиваемых визуальных и звуковых оповещений;
                            </p>
                            <p data-number="13">сбор и хранение информации о результатах сканирования радиоэфира, в том числе об обнаруженных БЛА;
                            </p>
                            <p data-number="14">сбор и хранение информации о результатах работы средств оптического обнаружения (фотографии и видео);
                            </p>
                            <p data-number="15">поиск информации о результатах сканирования по пространственным, временным и атрибутивным критериям, просмотр и удаление информации о результатах сканирования;
                            </p>
                            <p data-number="16">воспроизведение сохраненных оперативных данных за выбранный период с выбранной скоростью.
                            </p>
                        </div>
                        <h2>Состав:
                        </h2>
                        <div className='spisok'>
                            <p data-number="1">Сервер хранения и обработки с характеристиками не хуже: Intel Xeon E5 (не менее 12 физических ядер) – 2 шт., SDD RAID 4 ТБ, HDD RAID 12 ТБ, 64 ГБ ОЗУ, ИБП 1500 ВА. – 1-2 к-та;</p>
                            <p data-number="2">АРМ с характеристиками не хуже: Intel Core i7 (не менее 6 физических ядер), 1 ТБ HDD, 32 ГБ ОЗУ, видеокарта не хуже Nvidia 2080 или аналог; монитор 21-23” – 2шт., ИБП 600-800 ВА – 1 к-т
                            </p>
                            <p data-number="3">Телевизионная панель LCD 48-55" с кронштейном – 2 к-та.
                            </p>
                            <p data-number="4">Специальное программное обеспечение – 1 к-т.
                            </p>
                            <p data-number="5">Общесистемное программное обеспечение – 1 к-т.
                            </p>
                        </div>
                      
                     </div>
                </div>
            </div>
        </>
    );
};

export default Delta;
