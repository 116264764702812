import React, { useEffect } from 'react';
import img1 from '../../images/services-images/sred/sonata.jpg'
const Sonata = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Устройства виброакустической защиты Соната-АВ-4Б</h1>
                        <p>Cущественное увеличение стойкости защиты за счет многогенераторного независимого возбуждения заградительной помехи в нескольких точках и вследствие исключения электроакустического преобразования в излучателях.</p>
                        <p>Cущественное снижение стоимости комплексов виброакустической защиты вследствие предельной безизбыточности комплексов защиты (возможно комбинирование на одном питающем шлейфе любых сочетаний излучателей)</p>
                        <div className='forensic_back_mile'>

                            <img src={img1} alt='oscorp' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sonata;
