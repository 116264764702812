import React from 'react';
import ye2p1 from '../../images/services-images/antibpla/ye2p1.png'
import ye2p2 from '../../images/services-images/antibpla/ye2p2.png'
import ye2p3 from '../../images/services-images/antibpla/ye2p3.png'
const YellowBird2P = () => {

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Система защиты БЛА «YELLOWBIRD-2P»</h1>
                        <div className='bimage_vert'>
                            <img src={ye2p1} alt='garpun' />
                            <img src={ye2p2} alt='garpun' />
                            <img src={ye2p3} alt='garpun' />
                        </div>

                        <p>Система защиты от БЛА «Yellowbird-2P» является носимой версией системы защиты от БЛА «Yellowbird-2». Предназначена для обнаружения БЛА, приближающихся к объекту, передачи сигнала тревоги на пульт  управления и сигнализации, блокирования каналов управления и навигации БЛА.</p>
                        <p>Система является полностью автоматизированной и не требует участия оператора.</p>
                        <h2>Изделие обеспечивает:</h2>
                        <div className='spisok'>
                            <p data-number="1">Оперативное развертывание изделия в месте его использования;
                            </p>
                            <p data-number="2">Обнаружение радиосигналов канала управления БЛА (радиолиния Пульт управления – БЛА);
                            </p>
                            <p data-number="3">Обнаружение радиосигналов передачи данных БЛА (радиолиния БЛА – Пульт управления);
                            </p>
                            <p data-number="4">Активацию блока радиоэлектронного блокирования соответствующего частотного диапазона;
                            </p>
                            <p data-number="5">Выбор режима блокирования работы БЛА;
                            </p>
                            <p data-number="6">Дальность обнаружения БЛА 2500 м; дальность подавления БЛА 2000 м;</p>
                            <p data-number="7">Работу в автоматическом режиме с ведением журнала событий;
                            </p>
                            <p data-number="8">Обновление баз данных образцов нарушителей, в том числе модифицированных БЛА;
                            </p>
                            <p data-number="9">Возможность записи неизвестных сигналов для анализа (опционально);
                            </p>
                            <p data-number="10">Изменение дальностей функционирования под определенный объект (на этапе развертывания системы);
                            </p>
                            <p data-number="11">Отсутствие ложных срабатываний на иные устройства, функционирующие в тех же частотных диапазонах  (отношение количества пакетов цифровых сигналов или частотных каналов, на которые произошли ложные  срабатывания к общему их количеству – не более 0,001);
                            </p>
                            <p data-number="12">Возможность комплексного функционирования с системой оптического обнаружения «Хамелеон»;
                            </p>
                            <p data-number="13">Открытость для интегрирования в неё вновь вводимых систем.
                            </p>
                            
                        </div>
                        <h2>Варианты конструктивного исполнения:</h2>
                        <div className='spisok'>
                            <p data-number="1">Мобильное;</p>
                            <p data-number="2">Стационарное.</p>
                        </div>
                        <h2>Диапазон рабочих температур:</h2>
                        <div className='spisok'>
                            <p data-number="1">от - 20°С до + 45°С</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default YellowBird2P;
