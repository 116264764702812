import React from 'react';
import img1 from "../../images/services-images/at/pole.png"
const Pole = () => {

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Блокиратор беспроводной связи "ПОЛЕ"</h1>

                        <p>Изделие устанавливается на автотранспортное
                            средство и предназначено для блокирования
                            радиоуправляемых устройств (приемников),
                            работающих в стандартах сотовой связи (EGSM,
                            DCS, UMTS, LTE), WiFi, а также диапазонов VHF и
                            UHF (сигналы радиостанций).</p>
                        <div className='lgscreen'>

                        <img src={img1} alt='pole'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Pole;
