import React, { useEffect } from 'react';
import img1 from '../../images/services-images/sred/oscorp.png'
const Oscorp = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Анализатор спектра OSCOR Blue</h1>
                        <p>OSCOR Blue – портативный анализатор спектра, обладающий высокой скоростью развертки спектра и специальными функциями для выявления и локализации неизвестных сигналов, тестирования радиопередающих систем в широком спектре диапазона частот.</p>
                        <div className='forensic_back_mile'>

                        <img src={img1} alt='oscorp'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Oscorp;
