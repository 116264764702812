import React, { useEffect } from 'react';
import meshimg from '../../images/services-images/mesh/ace6.jpg'
const Ace6 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>

                        <h1>ACE 6 Technology</h1>

                        <div className='forensic_back_wise'>
                            <img src={meshimg} alt='forensic' />
                        </div>

                        <p>Данный вид оборудования предназначен для силовых структур, а так же для проведения поисков- спасательных операций в лесах в горной местности и тд.</p>
                        <p>mMESH - это беспроводное IP-решение с высокой пропускной способностью, обеспечивающее связь между людьми, транспортными средствами и командными центрами в самых неблагоприятных и нестабильных ситуациях. Продукты mMESH™, спроектированные, разработанные и произведенные компанией ACE6 Technology, используют радиочастотную технологию COFDM для создания надежных сетей в считанные минуты, даже в условиях отсутствия прямой видимости.
                        </p>
                        <p>Продукты mMESH, разработанные без концентраторов и не имеющие единой точки отказа, устраняют уязвимости, обеспечивая беспрепятственное взаимодействие с существующей инфраструктурой. Не требуется сложного программирования, а каждый узел mMESH™ может работать в качестве ретранслятора, обеспечивая расширенную зону покрытия в соответствии с вашими потребностями.</p>
                        <p>Применительно для правохранительных органов и вооруженных сил.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Ace6;
