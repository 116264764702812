import React from 'react';
import img1 from "../../images/services-images/at/teplo.png"
import img2 from "../../images/services-images/at/teplo2.png"
const Atshet = () => {

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Тепловизорный комплекс "Атшет"</h1>
                        <p>Комплекс "Атшет" предназначен для выявления и идентификации
                            посторонних объектов, людей, животных и транспорта на
                            контролируемой территории.</p>
                            <div className='lgscreen'>

                        <img src={img1} alt='pole'/>
                        </div>
                        <div className='lgscreen'>

                        <img src={img2} alt='pole'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Atshet;
