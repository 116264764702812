import React from 'react';
import { useLocation } from 'react-router-dom';

const NewsDetail = () => {
    const location = useLocation();
    const { newsItem } = location.state || {}; // Получаем новость из состояния

    if (!newsItem) {
        return <div>Новость не найдена.</div>;
    }

    return (
        <div className="news-detail">
            <h1>{newsItem.headline}</h1>
            <p>{newsItem.date}</p>
            <div className="news-image" style={{ backgroundImage: `url(${newsItem.image})` }}></div>
            <p>{newsItem.description}</p>
        </div>
    );
};

export default NewsDetail;