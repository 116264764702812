import React, { useEffect } from 'react';
import image_logo6 from '../../images/services-images/forensic/6.png'
import image_logo7 from '../../images/services-images/forensic/7.png'
import image_logo8 from '../../images/services-images/forensic/8.png'
import image_logo9 from '../../images/services-images/forensic/9.png'
import image_logo10 from '../../images/services-images/forensic/10.png'
import image_logo11 from '../../images/services-images/forensic/11.png'
import './Forensic.css'
const HW = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>

                        <h1>Аппаратные средства для комплексного исследования различных типов устройств</h1>
                        <div className='forensic_block'>
                            <div className='forensic_opis'>
                                <h2>Аппаратно-программный комплекс «Дубль-1»</h2>
                                <div className='forensic_logo'>
                                    <img src={image_logo6} alt='logo' />
                                </div>
                            </div>
                            <p className='p_white'>Предназначен для копирования информации по USB интерфейсам с персональных компьютеров, построенных на базе процессоров архитектуры х86.</p>

                        </div>
                        <div className='forensic_block'>
                            <div className='forensic_opis'>
                                <h2>Аппаратно-программный комплекс «Дубль-1» исп.2</h2>
                                <div className='forensic_logo'>
                                    <img src={image_logo7} alt='logo' />
                                </div>
                            </div>
                            <p className='p_white'>Предназначен для копирования информации по USB интерфейсам с исследуемых компьютеров, построенных на базе процессоров архитектуры х86, без необходимости их разборки.</p>
                        </div>
                        <div className='forensic_block'>
                            <div className='forensic_opis'>
                                <h2>Аппаратно-программный комплекс «Дубль-К»</h2>
                                <div className='forensic_logo'>
                                    <img src={image_logo8} alt='logo' />
                                </div>
                            </div>
                            <p className='p_white'>Предназначен для копирования информации с внешних флеш-накопителей, HDD и SSD дисков.</p>

                        </div>
                        <div className='forensic_block'>
                            <div className='forensic_opis'>
                                <h2>Аппаратно-программный комплекс «Дубль-А»</h2>
                                <div className='forensic_logo'>
                                    <img src={image_logo9} alt='logo' />
                                </div>
                            </div>
                            <p className='p_white'>Предназначен для автономного скоростного копирования данных с флеш-накопителей.</p>

                        </div>
                        <div className='forensic_block'>
                            <div className='forensic_opis'>
                                <h2>Блокиратор USB – USB,
                                    Блокиратор USB – SATA
                                </h2>
                                <div className='forensic_logo'>
                                    <img src={image_logo10} alt='logo' />
                                </div>
                            </div>
                            <p className='p_white'>Предназначены для блокирования любых команд записи (режим «Только чтение») на подключаемых носителях.
                            </p>
                        </div>
                        <div className='forensic_block'>
                            <div className='forensic_opis'>
                                <h2>Устройство деактивации автоматической блокировки экрана</h2>
                                <div className='forensic_logo'>
                                    <img src={image_logo11} alt='logo' />
                                </div>
                            </div>
                            <p className='p_white'>Предназначено для поддержания экрана исследуемого мобильного устройства во включённом состоянии.
                            </p>
                        </div>
                    </div>
                </div>


            </div>

        </>
    );
};

export default HW;
