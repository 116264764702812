import React from 'react';
import yem2 from '../../images/services-images/antibpla/yem2.png'
const YellowBirdM2 = () => {
    
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Система обнаружения БЛА «YELLOWBIRD-M2»</h1>
                        <div className='bimage_vert'>
                        <img src={yem2} alt='garpun'/>
                        </div>
                        <p>Система защиты от БЛА «Yellowbird-M2» предназначена для обнаружения БЛА, передачи сигнала тревоги на пульт управления и сигнализации, блокирования каналов управления и навигации БЛА.</p>
                        <p>Является защищенной версией изделия «Yellowbird-2». Создана для использования в сложных климатических и техногенных условиях (повышенная влажность воздуха, высокая солнечная активность, пылевые бури, химические и нефтеперерабатывающие предприятия с химически активной внешней средой). Устройство находится внутри защитного радиопрозрачного укрытия, аналогичного укрытию морской антенны спутникового телевидения, и имеет встроенную систему создания и поддержания собственного микроклимата, обеспечивающего невиданную ранее отказоустойчивость внутренних компонентов изделия. В условиях недостаточной температуры окружающей среды происходит нагрев воздуха внутри корпуса, а в условиях повышенной температуры – его охлаждение. При работе встроенного кондиционера выделяется конденсат, при необходимости устройство может быть подключено к системе отвода жидкости. Монтаж «Yellowbird-M2» производится непосредственно на твёрдой площадке без использования переносной треноги
                        </p>
                        <h2>Технические характеристики:</h2>
                        <div className='spisok'>
                            <p data-number="1">Максимальная выходная мощность блокирующего сигнала не менее - 8 Вт</p>
                            <p data-number="2">Максимальное количество обнаруживаемых диапазонов - 5</p>
                            <p data-number="3">Возможность обнаружения в диапазонах - 433/868/1200/2400/5800 МГц</p>
                            <p data-number="4">Дальность обнаружения - до 2500 м</p>
                            <p data-number="5">Возможность блокирования в диапазонах частот - 433/868/1200/1600/2400/5800 МГц</p>
                            <p data-number="6">Максимальное количество блокируемых диапазонов - 6</p>
                            <p data-number="7">Глубина дискретной регулировки уровня выходного сигнала - 10 дБ</p>
                            <p data-number="8">Дальность блокирования - до 2000 м</p>
                            <p data-number="9">Диапазон рабочих температур - от -40°C до +55°C</p>
                            <p data-number="10">Электропитание - 220 В, 50 Гц</p>
                            <p data-number="11">Потребляемая мощность - не более 1400 Вт</p>
                        </div>
                        <h2>Система поддержания микроклимата:</h2>
                        <div className='spisok'>
                            <p data-number="1">Мощность охлаждения согласно ISO 5151 - 2700 Вт</p>
                            <p data-number="2">Теплопроизводительность - 3000 Вт</p>
                            <p data-number="3">Ток потребления при охлаждении - 4,3 А</p>
                            <p data-number="4">Ток потребления при нагреве - 4,8 А</p>
                        </div>
                        <h2>Масса и габаритные размеры:</h2>
                        <div className='spisok'>
                            <p data-number="1">Габаритные размеры (Д х Ш х В) - 1080 мм х 1080 мм х 1090 мм</p>
                            <p data-number="2">Вес изделия с кондиционером и радиопрозрачным укрытием  - не более 85 кг</p>
                            
                        </div>
                     </div>
                </div>
            </div>
        </>
    );
};

export default YellowBirdM2;
