import React, { useState, useEffect } from "react";
import './Services.css';

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Services = () => {
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);

    const services = [
        {
            id: 1,
            title: "Системы видеонаблюдения",
            description: "ТОО «АскомМЕТ» осуществляет проектирование, поставку, монтаж и инсталляцию комплексных систем видеонаблюдения от отечественных и мировых производителей, а также интегрирование новейших современных систем в уже имеющиеся комплексы безопасности.",
            background: require('../../images/secure.jpg'),
            path: "/video"
        },
        {
            id: 2,
            title: "Телекомуникации и связь",
            description: "ТОО «АскомМЕТ» предоставляет услуги по проектированию, поставке, монтажу и инсталляции современных телекоммуникационных систем и средств связи от ведущих отечественных и мировых производителей.",
            background: require('../../images/network.png'),
            path: "/integratedsecuritysystem"
        },
        {
            id: 3,
            title: "Средства защиты информации и технические средства поиска каналов утечки информации",
            description: "",
            background: require('../../images/secure_info.jpg'),
            path: "/informsec"
        },
        {
            id: 4,
            title: "Цифровая криминалистика",
            description: "Компания «АскомМЕТ» является официальным партнером ведущих мировых компаний по цифровой криминалистике.",
            background: require('../../images/sts.jpg'),
            path: "/forensic"
        },
        {
            id: 5,
            title: "Анти - БЛА",
            description: "Решения по борьбе с беспилотными летательными аппаратами коммерческого типа.",
            background: require('../../images/antibpla.jpg'),
            path: "/antidrone"
        },
        {
            id: 6,
            title: "Антитеррористическая защита",
            description: "На рынке техники существует большое количество оборудования, предназначенного для противодействия терроризму. Все они в большей или меньшей степени эффективны, и их применение обеспечивает определенный уровень защищённости.",
            background: require('../../images/terror.jpg'),
            path: "/antiterror"
        },
        {
            id: 7,
            title: "СТС",
            description: "Компания «АскомМЕТ» осуществляет реализацию и ремонт специальных технических средств (СТС) для субъектов оперативно-розыскной деятельности.",
            background: require('../../images/krim.png'),
            path: "/sts"
        },
        {
            id: 8,
            title: "Система радиосвязи Mesh",
            description: "Передовая технология для передачи любых типов данных по радио каналу с максимальной отказоустойчивостью.",
            background: require('../../images/services-images/mesh/mesh.jpeg'),
            path: "/mesh"
        },
    ];

    const previousIndex = (currentIndex + services.length - 1) % services.length;
    const nextIndex = (currentIndex + 1) % services.length;

    const handleNext = () => {
        setCurrentIndex((currentIndex + 1) % services.length);
    };

    const handlePrevious = () => {
        setCurrentIndex((currentIndex + services.length - 1) % services.length);
    };

    const handleMoreDetails = (service) => {
        navigate(service.path);
    };

    const handleBlockClick = (index) => {
        setCurrentIndex(index);
    };
    useEffect(() => {
        window.particlesJS("services-background", {
            particles: {
                number: { value: 30, density: { enable: true, value_area: 800 } },
                color: { value: "#ffffff" },
                shape: { type: "star" },
                opacity: { value: 0.5, random: true },
                size: { value: 5, random: true },
                line_linked: { enable: true },
                move: { enable: true, speed: 4, direction: "none", random: true, straight: false, out_mode: "out" },
            },
            interactivity: {
                detect_on: "canvas",
                events: { onhover: { enable: true, mode: "repulse" }, onclick: { enable: true, mode: "push" }, resize: true },
            },
            retina_detect: true,
        });
    }, []);
    return (
        <>
            <div className="section" id="services-section">
                <div id="services-background" />
                <div className="section-title"><h1>НАШИ УСЛУГИ</h1></div>
                <div className="service-carousel">
                    <button className="carousel-arrow left-arrow" onClick={handlePrevious}><FaArrowLeft /></button>
                    <div
                        className={`service-block ${currentIndex === previousIndex ? 'left' : ''}`}
                        onClick={() => handleBlockClick(previousIndex)}
                    >
                        <div
                            className="service-image"
                            style={{ backgroundImage: `url(${services[previousIndex].background})` }}
                        />
                        <h2 className="service-title">{services[previousIndex].title}</h2>
                    </div>

                    <div className={`service-block center`}>
                        <div
                            className="service-image"
                            style={{ backgroundImage: `url(${services[currentIndex].background})` }}
                        />
                        <h2 className="service-title">{services[currentIndex].title}</h2>
                        <p className="service-description">{services[currentIndex].description}</p>
                        <button className="more-details-button" onClick={() => handleMoreDetails(services[currentIndex])}>Подробнее</button>
                    </div>

                    <div
                        className={`service-block ${currentIndex === nextIndex ? 'right' : ''}`}
                        onClick={() => handleBlockClick(nextIndex)}
                    >
                        <div
                            className="service-image"
                            style={{ backgroundImage: `url(${services[nextIndex].background})` }}
                        />
                        <h2 className="service-title">{services[nextIndex].title}</h2>
                    </div>

                    <button className="carousel-arrow right-arrow" onClick={handleNext}><FaArrowRight /></button>
                </div>
            </div>
        </>
    );
};

export default Services;
