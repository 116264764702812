import React from 'react';
import img1 from "../../images/services-images/at/fillin.png"
const Filin = () => {

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Комплекс выявления слежки за автомобилем в потоке "ФИЛИН"</h1>
                        <div className='lgscreen'>

                            <img src={img1} alt='filin' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Filin;
