import React, { useEffect } from 'react';
import tel_1 from "../../images/services-images/tel_1.jpg"

const Telefonnyyekommunikatsii = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Телефонные коммуникации</h1>
                        <img src={tel_1} alt='el_set' />
                        <p>В настоящее время уже невозможно представить себе, как будет функционировать офис организации без современных средств связи. Офисная мини АТС - это телекоммуникационное устройство, обеспечивающее связь между внутренними телефонными линиями и линиями общего пользования. Позволяет коммутировать внутренних абонентов между собой и с абонентами находящимися на внешних линиях телефонной связи. Такие телекоммуникационные системы дали возможность эффективно использовать городские линии, что значительно снизило затраты на связь.</p>
                        <h2>Классификация офисных мини АТС</h2>
                        <h3>Офисные АТС различаются по ряду факторов:</h3>
                        <div className='spisok'>
                            <p data-number="1">Способом работы: аналоговые и цифровые. Цифровые мини АТС по сравнению с аналоговыми имеют расширенный список функций, в частности к такой АТС можно подключить линии ISDN или поток Е1;</p>
                            <p data-number="2">Базовой конфигурацией, т.е. количеством городских и внутренних линий;</p>
                            <p data-number="3">Максимальной конфигурацией.</p>
                        </div>
                        </div>
                </div>
            </div>
        </>
    );
};

export default Telefonnyyekommunikatsii;
