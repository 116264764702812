import React, {useEffect} from 'react';
import banner_security from '../../images/services-images/banner_security.jpg'
const InformSec = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Cредства защиты информации и технические средства поиска каналов утечки информации</h1>
                        <img src={banner_security} alt='banner'/>
                        <h2>Средства защиты информации:</h2>
                        <div className='spisok'>
                            <p data-number="1">технические;</p>
                            <p data-number="2">программно-аппаратные;</p>
                            <p data-number="3">программные.</p>
                        </div>
                        <h2>Технические средства поиска каналов утечки информации:</h2>
                        <div className='spisok'>
                            <p data-number="1">комплексы выявления акустопараметрических каналов утечки информации и радиоконтроля;</p>
                            <p data-number="2">комплексы выявления радиопередающих технических средств разведки;</p>
                            <p data-number="3">комплексы выявления технических средств разведки в каналах цифровой радиосвязи;</p>
                            <p data-number="3">комплексы активного контроля цепей питания технических средств;</p>
                            <p data-number="3">устройства проверки проводных линий;</p>
                            <p data-number="3">нелинейные локаторы;</p>
                            <p data-number="3">оборудование для визуально-оптического контроля;</p>
                            <p data-number="3">средства специального рентгеновского контроля.</p>
                        </div>
                            <p>На данный момент существует достаточно широкий выбор средств защиты информации как от несанкционированного доступа так и от утечки по техническим каналам.</p>
                            <p>Для предупреждения и предотвращения утечки информации по техническим каналам, должны быть выполнены следующие условия:</p>
                        <div className='spisok'>
                            <p data-number="1">Использование сертифицированных серийно выпускаемых в защищенном исполнении технических средств обработки, передачи и хранения информации</p>
                            <p data-number="2">Использование технических средств, удовлетворяющих требованиям стандартов по электромагнитной совместимости</p>
                            <p data-number="3">Использование сертифицированных средств защиты информации</p>
                            <p data-number="4">Размещение объектов защиты на максимально возможном расстоянии от границ КЗ</p>
                            <p data-number="5">Размещение понижающих трансформаторных подстанций электропитания и контуров заземления объектов защиты в пределах КЗ</p>
                            <p data-number="6">Использование сертифицированных систем гарантированного электропитания (источников бесперебойного питания)</p>
                            <p data-number="7">Развязка цепей электропитания объектов защиты с помощью сетевых помехоподавляющих фильтров, блокирующих (подавляющих) информативный сигнал</p>
                            <p data-number="8">Электромагнитная развязка между информационными цепями, по которым циркулирует защищаемая информация и линиями связи, другими цепями ВТСС, выходящими за пределы КЗ</p>
                            <p data-number="9">Использование защищенных каналов связи</p>
                        </div>
                        <p>От утечки информации за счет несанкционированного доступа применяют следующее:</p>
                        <div className='spisok'>
                            <p data-number="1">Реализация разрешительной системы допуска исполнителей (пользователей, обслуживающего персонала) к информации и связанным с ее использованием работам, документам</p>
                            <p data-number="2">Ограничение доступа персонала и посторонних лиц в защищенное помещение и помещения, где размещены средства информатизации и коммуникационное оборудование, а также хранятся носители информации</p>
                            <p data-number="3">Разграничение доступа пользователей и обслуживающего персонала к информационным ресурсам, программным средствам обработки (передачи) и защиты информации</p>
                            <p data-number="4">Регистрация действий пользователей и обслуживающего персонала, контроль несанкционированного доступа и действий пользователей, обслуживающего персонала и посторонних лиц</p>
                            <p data-number="5">Учет и надежное хранение бумажных и машинных носителей конфиденциальной информации и их обращение, исключающее хищение, подмену и уничтожение</p>
                            <p data-number="6">Использование сертифицированных по требованиям безопасности информации специальных защитных знаков, создаваемых на основе физико-химических технологий для контроля доступа к объектам защиты и для защиты документов от подделки</p>
                            <p data-number="7">Резервирование технических средств, дублирование массивов и носителей информации</p>
                            <p data-number="8">Предотвращение внедрения в АС программ-вирусов, программных закладок</p>
                            <p data-number="9">Размещение дисплеев и других средств отображения информации, исключающее ее несанкционированный просмотр</p>
                            <p data-number="8">Организация физической защиты помещений и собственно технических средств обработки информации с использованием технических средств охраны, предотвращающих или существенно затрудняющих проникновение в здания, помещения посторонних лиц, хищение документов и носителей информации, самих средств информатизации</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InformSec;
