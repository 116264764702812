import React, { useEffect } from 'react';

const MobilniyKompleks = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Мобильный комплекс определения идентификаторов мобильных терминалов и их местоположения на базе транспортных средств</h1>
                        <p className='warning'><i>
                            Информацию заинтересованным лицам можно получить по телефонам +7 (7172) 55-27-93, +7 (7172) 55-27-97 <br/>или в офисе компании по адресу: Республики Казахстан, ZOOX6D6, г. Астана, ул. Сарысу, д.12/1.
                            </i>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MobilniyKompleks;
