import React, { useEffect } from 'react';
import img1 from '../../images/services-images/sred/orion.jpg'
const Orion = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Локатор нелинейностей ORION</h1>
                        <p>Нелинейный локатор ORION 2.4 является передовой разработкой в нелинейной радиолокации и предназначен для обнаружения любых электронных устройств. ORION 2.4 обнаруживает любые устройства, содержащие полупроводниковые компоненты, вне зависимости от того, находятся они во включенном или выключенном состоянии. Позволяет эффективно обнаруживать как крупные, так и миниатюрные электронные компоненты и устройства, в том числе сотовые телефоны, диктофоны, микрофоны, Flash карты, micro SIM карты.</p>
                        <div className='forensic_back_mile'>

                            <img src={img1} alt='oscorp' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Orion;
