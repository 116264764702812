import React, { useEffect } from 'react';

import mainimg1 from '../../images/services-images/videocam/pelcocam1.avif'
import mainimg2 from '../../images/services-images/videocam/pelcocam2.avif'
import mainimg3 from '../../images/services-images/videocam/pelcocam3.avif'
import mainimg4 from '../../images/services-images/videocam/pelcocam4.avif'
const Pelco = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Pelco</h1>

                        <p>Pelco - мировой лидер в области разработки и производства инновационных решений для систем видеонаблюдения. Компания предлагает широкий ассортимент оборудования, включая IP-камеры, видеорегистраторы, программное обеспечение для управления видео и системы аналитики. Благодаря своему многолетнему опыту и постоянному стремлению к совершенствованию, Pelco занимает лидирующие позиции на рынке безопасности.</p>
                        <div className='forensic_block'>
                            <div className='forensic_opis'>
                                <h2>Silent Sentinel Jaegar</h2>
                                <div className='forensic_back_mile'>
                                    <img src={mainimg1} alt='main' />
                                </div>
                                <p>Обнаруживайте людей и транспортные средства на больших расстояниях с помощью высокоточных тепловых и видимых изображений в условиях плохой видимости.</p>

                            </div>
                            <h2>Особенности:</h2>
                            
                            <div className='spisok'>

                                <p>Возможность видеть с большого расстояния в любых условиях, в том числе людей на расстоянии до 24 км и транспортные средства на расстоянии до 31 км.</p>
                                <p>Благодаря прочному корпусу Jaegar готов к работе в суровых условиях окружающей среды и промышленности.</p>
                                <p></p>
                            </div>

                            
                        </div>
                        <div className='forensic_block'>
                            <div className='forensic_opis'>
                                <h2>Silent Sentinel Jaegar</h2>
                                <div className='forensic_back_mile'>
                                    <img src={mainimg2} alt='main' />
                                </div>
                                <p>Обнаруживайте людей и транспортные средства на больших расстояниях с помощью высокоточных тепловых и видимых изображений в условиях плохой видимости.</p>

                            </div>
                        </div>
                        <div className='forensic_block'>
                            <div className='forensic_opis'>
                                <h2>ExSite Pro Bullet</h2>
                                <div className='forensic_back_mile'>
                                    <img src={mainimg3} alt='main' />
                                </div>
                                <p>Экономичные камеры с функцией аналитики, созданные для опасных сред.</p>

                            </div>
                        </div>

                        <div className='forensic_block'>
                            <div className='forensic_opis'>
                                <h2>Motorola Solutions VB400</h2>
                                <div className='forensic_back_mile'>
                                    <img src={mainimg4} alt='main' />
                                </div>
                                <p>Документируйте и записывайте видео и аудио от первого лица для полного понимания ситуации.</p>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
};

export default Pelco;
