import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';

const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isNavbarOpen, setIsNavbarOpen] = useState(true); 
    const [isDynamic, setIsDynamic] = useState(window.innerWidth < 1110);

    useEffect(() => {
        // Обработчик изменения размера окна
        const handleResize = () => {
            const isSmallScreen = window.innerWidth < 1424;
            setIsDynamic(isSmallScreen);

            // Если экран больше 1110px, всегда показываем навбар
            if (!isSmallScreen) {
                setIsNavbarOpen(true);
            }
        };

        window.addEventListener('resize', handleResize);

        // Удаляем слушатель при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleBackClick = () => {
        const parentPath = location.pathname.split('/').slice(0, -1).join('/');
        if (parentPath !== '') {
            navigate(parentPath); // Переход на родительский путь
        } else {
            navigate('/'); // Переход на главную
            setTimeout(() => {
                const servicesSection = document.getElementById('services');
                if (servicesSection) {
                    servicesSection.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100); // Небольшая задержка, чтобы дождаться рендера страницы
        }
    };

    const toggleNavbar = () => {
        if (isDynamic) {
            setIsNavbarOpen(!isNavbarOpen);
        }
    };

    const antiterrorLinks = [
        { to: "/antiterror/svod", label: "Генератор сигналов" },
        { to: "/antiterror/pole", label: "Блокиратор беспроводной связи" },
        { to: "/antiterror/atshet", label: "Тепловизорный комлпекс" },
        { to: "/antiterror/kurator", label: "Досмотровой комплекс" },
        { to: "/antiterror/filin", label: "Комплекс выявления слежки за автомобилем в потоке" },
        { to: "/antiterror/ten", label: "Стационарный комплекс ретрансляционных помех" }
    ];

    const antidroneLinks = [

        { to: "/antidrone/dirk-3", label: "Ручной комплекс борьбы с БЛА «DIRK-3»" },
        { to: "/antidrone/owl2", label: "Система обнаружения БЛА «OWL-2»" },
        { to: "/antidrone/yellowbird-2", label: "Система защиты от БЛА «YellowBird-2»" },
        { to: "/antidrone/yellowbird-M2", label: "Система защиты от БЛА «YellowBird-M2»" },
        { to: "/antidrone/yellowbird-2P", label: "Система защиты от БЛА «YellowBird-2P»" },
        { to: "/antidrone/chameleon", label: "Система оптического обнаружения «Сhameleon»" },
        { to: "/antidrone/chameleon120", label: "Система оптического обнаружения «Сhameleon120»" },
        { to: "/antidrone/delta", label: "Аппаратно-программный комплекс «DELTA»" },
    ];

    const informsecLinks = [
        { to: "/informsec/oscorp", label: "Анализатор спектра OSCOR Blue" },
        { to: "/informsec/talan", label: "Анализатор проводных линий" },
        { to: "/informsec/andre", label: "Многофункциональ ный поисковый прибор" },
        { to: "/informsec/orion", label: "Локатор нелинейностей" },
        { to: "/informsec/sonata", label: "Устройства виброакустической защиты" },
        { to: "/informsec/usj-02-disc", label: "Ультразвуковой глушитель USJ-02-Disc" },
        
    ];

    const integratedSecuritySystemLinks = [
        // { to: "/integratedsecuritysystem/videonablyudeniye", label: "Видеонаблюдение" },
        // { to: "/integratedsecuritysystem/skud", label: "СКУД" },
        { to: "/integratedsecuritysystem/kompyuternyye-seti", label: "Компьютерные сети" },
        { to: "/integratedsecuritysystem/elektricheskiye-seti", label: "Электрические сети" },
        // { to: "/integratedsecuritysystem/okhrannaya-signalizatsiya", label: "Охранная сигнализация" },
        // { to: "/integratedsecuritysystem/pozharnaya-signalizatsiya", label: "Пожарная сигнализация" },
        { to: "/integratedsecuritysystem/telefonnyye-kommunikatsii", label: "Телефонные коммуникации" },
        // { to: "/integratedsecuritysystem/sistema-umnyy-dom", label: "Система Умный дом" },
        // { to: "/integratedsecuritysystem/intellektualnoye-zdaniye", label: "Интеллектуальное здание" },
    ];

    const forensicLinks = [
        { to: "/forensic/os", label: "Программные средства для комплексного исследования различных типов устройств" },
        { to: "/forensic/hw", label: "Аппаратные средства для комплексного исследования различных типов устройств" },
    ];

    const stsLinks = [
        { to: "/sts/sredstva", label: "Средства для негласного контроля аудио, видеоинформации, в том числе удаленный контроль" },
        { to: "/sts/oborudovanie", label: "Оборудование обработки запирающих устройств, охранных сигнализаций" },
        { to: "/sts/apksotoviy", label: "АПК анализа биллинга данных сотовых операторов" },
        { to: "/sts/mobilniykompleks", label: "Мобильный комплекс определения идентификаторов мобильных терминалов и их местоположения на базе транспортных средств" },
        { to: "/sts/apktsifrovoy", label: "АПК получения и анализа информации различных цифровых устройств" },
    ];

    const videoLinks = [
        { to: "/video/pelco", label: "Pelco" },
        { to: "/video/avigilon", label: "Avigilon" },
        { to: "/video/wisenet", label: "Wisenet" },
        { to: "/video/magos", label: "Magos" },
        { to: "/video/milestone", label: "Milestone Systems" },
        { to: "/video/briefcam", label: "BriefCam" },
    ];

    const meshLinks = [
        { to: "/mesh/rajant", label: "Rajant Kinetic Mesh" },
        { to: "/mesh/ace6", label: "ACE6 Technology" },
        { to: "/mesh/nbmesh", label: "NBMesh" },

    ]

    const renderLinks = () => {
        if (location.pathname.startsWith('/antiterror')) {
            return antiterrorLinks.map((link, index) => (
                <li key={index}>
                    <NavLink
                        to={link.to}
                        className={({ isActive }) => isActive ? "active-link" : ""}
                        style={{ display: 'block', width: '100%' }}
                    >
                        {link.label}
                    </NavLink>
                </li>
            ));
        } else if (location.pathname.startsWith('/antidrone')) {
            return antidroneLinks.map((link, index) => (
                <li key={index}>
                    <NavLink
                        to={link.to}
                        className={({ isActive }) => isActive ? "active-link" : ""}
                        style={{ display: 'block', width: '100%' }}
                    >
                        {link.label}
                    </NavLink>
                </li>
            ));
        } else if (location.pathname.startsWith('/informsec')) {
            return informsecLinks.map((link, index) => (
                <li key={index}>
                    <NavLink
                        to={link.to}
                        className={({ isActive }) => isActive ? "active-link" : ""}
                        style={{ display: 'block', width: '100%' }}
                    >
                        {link.label}
                    </NavLink>
                </li>
            ));
        } else if (location.pathname.startsWith('/forensic')) {
            return forensicLinks.map((link, index) => (
                <li key={index}>
                    <NavLink
                        to={link.to}
                        className={({ isActive }) => isActive ? "active-link" : ""}
                        style={{ display: 'block', width: '100%' }}
                    >
                        {link.label}
                    </NavLink>
                </li>
            ));
        } else if (location.pathname.startsWith('/integratedsecuritysystem')) {
            return integratedSecuritySystemLinks.map((link, index) => (
                <li key={index}>
                    <NavLink
                        to={link.to}
                        className={({ isActive }) => isActive ? "active-link" : ""}
                        style={{ display: 'block', width: '100%' }}
                    >
                        {link.label}
                    </NavLink>
                </li>
            ));
        } else if (location.pathname.startsWith('/sts')) {
            return stsLinks.map((link, index) => (
                <li key={index}>
                    <NavLink
                        to={link.to}
                        className={({ isActive }) => isActive ? "active-link" : ""}
                        style={{ display: 'block', width: '100%' }}
                    >
                        {link.label}
                    </NavLink>
                </li>
            ));
        } else if (location.pathname.startsWith('/mesh')) {
            return meshLinks.map((link, index) => (
                <li key={index}>
                    <NavLink
                        to={link.to}
                        className={({ isActive }) => isActive ? "active-link" : ""}
                        style={{ display: 'block', width: '100%' }}
                    >
                        {link.label}
                    </NavLink>
                </li>
            ));
        }else if (location.pathname.startsWith('/video')) {
            return videoLinks.map((link, index) => (
                <li key={index}>
                    <NavLink
                        to={link.to}
                        className={({ isActive }) => isActive ? "active-link" : ""}
                        style={{ display: 'block', width: '100%' }}
                    >
                        {link.label}
                    </NavLink>
                </li>
            ));
        }
        return null;
    };

    return (
        <nav className={`services-navbar ${isNavbarOpen ? 'open' : ''}`}>
            {/* Кнопка управления показывается только на маленьких экранах */}
            {isDynamic && (
                <button className="toggle-button" onClick={toggleNavbar}>
                    {isNavbarOpen ? '×' : '☰'}
                </button>
            )}
            <ul>
                {renderLinks()}
                <li>
                    <button onClick={handleBackClick} className="but-back">Назад</button>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
