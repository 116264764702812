import React, { useEffect } from 'react';
import image_logo1 from '../../images/services-images/forensic/1.png'
import image_logo2 from '../../images/services-images/forensic/2.png'
import image_logo3 from '../../images/services-images/forensic/3.png'
import image_logo4 from '../../images/services-images/forensic/4.png'
import image_logo5 from '../../images/services-images/forensic/5.png'
import './Forensic.css'
const OS = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>

                        <h1>Программные средства для комплексного исследования различных типов устройств</h1>
                        <div className='forensic_block'>
                            <div className='forensic_opis'>
                                <h2>Мобильный Криминалист Эксперт Плюс</h2>
                                <div className='forensic_logo'>
                                    <img src={image_logo1} alt='logo' />
                                </div>
                            </div>
                            <p className='p_white'>«Эксперт Плюс» разработан для проведения комплексной цифровой экспертизы данных из мобильных устройств, облачных сервисов, дронов и персональных компьютеров. Извлекайте, сохраняйте и анализируйте информацию с помощью самых передовых инструментов, предоставляемых нашим комплексом.</p>
                            <p className='p_white'>Возможности программы позволяют в кратчайшие сроки найти важные цифровые следы для как доказательственная основа в суде.</p>
                        </div>
                        <div className='forensic_block'>
                            <div className='forensic_opis'>
                                <h2>Мобильный Криминалист Десктоп</h2>
                                <div className='forensic_logo'>
                                    <img src={image_logo2} alt='logo' />
                                </div>
                            </div>
                            <p className='p_white'>«Десктоп» — современный многофункциональный инструмент, который позволяет извлекать, расшифровывать и анализировать ключевые данные из ПК, ноутбуков и серверов, работающих под управлением операционных систем Windows, macOS, GNU/Linux, а также из внешних дисков, образов жестких дисков, виртуальных машин.</p>
                        </div>
                        <div className='forensic_block'>
                            <div className='forensic_opis'>
                                <h2>Elcomsoft Premium Forensic Bundle</h2>
                                <div className='forensic_logo'>
                                    <img src={image_logo3} alt='logo' />
                                </div>
                            </div>
                            <p className='p_white'>Полный набор продуктов «Элкомсофт» в максимальной редакции в едином пакете. Продукты для снятия парольной защиты и мобильной криминалистики с поддержкой максимального числа форматов данных, мобильных систем и облачных сервисов.</p>

                        </div>
                        <div className='forensic_block'>
                        <div className='forensic_opis'>
                            <h2>Аналитический Центр Криминалист</h2>
                            <div className='forensic_logo_spec'>
                                <img src={image_logo4} alt='logo' />
                            </div>
                        </div>
                        <p className='p_white'>«Аналитический Центр Криминалист» — аналитический продукт в экосистеме «Мобильный Криминалист» от компании «МКО Системы». Позволяет одному или нескольким пользователям в любой момент одновременно анализировать большие объемы данных из полной базы загруженных извлечений, хранящихся на сервере.</p>
                       
                        </div>
                        <div className='forensic_block'>
                        <div className='forensic_opis'>
                            <h2>VR-Expert</h2>
                            <div className='forensic_logo'>
                                <img src={image_logo5} alt='logo' />
                            </div>
                        </div>
                        <p className='p_white'>Комплексное решение для работы с видеозаписывающими устройствами, файлами образами накопителей информации и  видеофайлами, позволяющими обеспечить поиск, анализ и извлечение необходимой визуальной информации, а также другой криминалистически значимой информации, содержащейся
                            как в явном, так и удаленном виде.
                        </p>
                        </div>
                       
                    </div>


                </div>
            </div>
        </>
    );
};

export default OS;
