import React, { useEffect } from 'react';
import ksb from '../../images/services-images/ksb.jpg'
const IntegratedSecuritySystem = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Телекомуникации и связь</h1>
                        <img src={ksb} alt='ksb' />
                        <p>ТОО «АскомМЕТ» осуществляет проектирование, поставку, монтаж и инсталляцию комплексных систем безопасности от отечественных и мировых производителей, а также интегрирование новейших современных систем в уже имеющейся комплексы безопасности.</p>
                    
                        
                    </div>
                </div>
            </div>
        </>
    );
};

export default IntegratedSecuritySystem;
