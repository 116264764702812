import React, { useEffect } from 'react';
import meshimg from '../../images/services-images/mesh/raj.jpg'
const Rajant = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>

                        <h1>Rajant Kinetic Mesh</h1>

                        <div className='forensic_back_mile'>
                            <img src={meshimg} alt='forensic' />
                        </div>

                       <p>Данные вид оборудования идеально подходит для стационарного размещения и передачи данных таких как изображения с камер видео-наблюдения и других типов данных.</p>
                       <h2>Максимальная адаптация сети при легкости и удобстве развертывания беспроводных узлов BreadCrumb</h2>
                       <p>Используя промышленные беспроводные сетевые узлы BreadCrumb компании Rajant, работающие под управлением фирменного патентованного сетевого программного обеспечения InstaMesh®, вы можете создать самовосстанавливающуюся сеть, способную динамически адаптироваться к перемещениям производственного персонала и оборудования в любой обстановке. При этом ваша сеть всегда будет оставаться неповрежденной, доступной и защищенной от помех. Каждый узел BreadCrumb может соединяться с множеством соседних узлов через множество линий связи, обеспечивая полное резервирование соединений по всей сети.</p>
                       <p>Программное обеспечение InstaMesh® управляет всем трафиком и автоматически оптимизирует сетевые соединения на основе имеющихся физических условий и доступных частот. Любой узел BreadCrumb может быть стационарным или мобильным, выполнять функции инфраструктуры или граничного узла сети. Узлы с малой занимаемой площадью и малым весом легко устанавливаются на транспортных средствах, башнях, столбах освещения и т.д.</p>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Rajant;
